import {
  ConnectedPosition,
  Overlay,
  OverlayRef,
  PositionStrategy,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  Directive,
  ElementRef,
  HostListener,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import {
  TooltipContainerComponent,
  TOOLTIP_DATA,
} from './tooltip-container.component';

@Directive({
  selector: '[sidkikTooltip]',
})
export class TooltipDirective implements OnDestroy, OnChanges {
  @Input('sidkikTooltip') tip!: string;
  @Input() showDelay = 0;
  @Input() direction = 'any';
  @Input() disappear = false;

  @HostListener('mouseenter')
  @HostListener('focus')
  showTooltip(): void {
    if (!this.disappear) {
      if (this.overlayRef?.hasAttached() === true) {
        return;
      }

      this.attachTooltip();
    }
  }

  @HostListener('mouseleave')
  @HostListener('blur')
  hideTooltip(): void {
    if (this.overlayRef?.hasAttached() === true) {
      this.overlayRef?.detach();
    }
  }

  private overlayRef: OverlayRef | null = null;

  constructor(
    private element: ElementRef<HTMLElement>,
    private overlay: Overlay,
    private viewContainer: ViewContainerRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['disappear']?.currentValue === true) {
      this.hideTooltip();
    }
  }

  ngOnDestroy(): void {
    this.overlayRef?.dispose();
  }

  private attachTooltip(): void {
    if (this.overlayRef === null) {
      const positionStrategy = this.getPositionStrategy();
      this.overlayRef = this.overlay.create({ positionStrategy });
    }

    const injector = Injector.create({
      providers: [
        {
          provide: TOOLTIP_DATA,
          useValue: { tip: this.tip, delay: this.showDelay },
        },
      ],
      parent: this.viewContainer.injector,
    });

    const component = new ComponentPortal(
      TooltipContainerComponent,
      null,
      injector
    );

    this.overlayRef.attach(component);
  }

  private getPositionStrategy(): PositionStrategy {
    const any = [
      {
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom',
        panelClass: 'top',
        offsetY: -7,
      },
      {
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top',
        panelClass: 'bottom',
        offsetY: 7,
      },
    ];

    const side = [
      {
        originX: 'start',
        originY: 'center',
        overlayX: 'end',
        overlayY: 'center',
        panelClass: 'left',
        offsetX: -7,
      },
      {
        originX: 'end',
        originY: 'center',
        overlayX: 'start',
        overlayY: 'center',
        panelClass: 'right',
        offsetX: 7,
      },
    ];

    if (this.direction === 'side') {
      return this.overlay
        .position()
        .flexibleConnectedTo(this.element)
        .withPositions(side as unknown as ConnectedPosition[]);
    }
    return this.overlay
      .position()
      .flexibleConnectedTo(this.element)
      .withPositions(any as unknown as ConnectedPosition[]);
  }
}
