/** Page */
export const loadPagesType = '[library/page] load pages';
export const loadPagesAndSelectPageType =
  '[library/page] load pages and select page';
export const loadPagesSuccessType = '[library/page] load pages success';
export const loadPagesAndSelectPageSuccessType =
  '[library/page] load pages and select page success';
export const loadPagesFailureType = '[library/page] load pages failure';
export const loadPagesAndSelectPageFailureType =
  '[library/page] load pages and select page failure';

export const selectPageType = '[library/page] select page';

export const addPageType = '[library/page] add page';
export const addPageSuccessType = '[library/page] add page success';
export const addPageFailureType = '[library/page] add page failure';

export const updatePageType = '[library/page] update page';
export const updatePageSuccessType = '[library/page] update page success';
export const updatePageFailureType = '[library/page] update page failure';

/** Email */
export const loadEmailsType = '[library/email] load emails';
export const loadEmailsAndSelectEmailType =
  '[library/email] load emails and select email';
export const loadEmailsSuccessType = '[library/email] load emails success';
export const loadEmailsAndSelectEmailSuccessType =
  '[library/email] load emails and select email success';
export const loadEmailsFailureType = '[library/email] load emails failure';
export const loadEmailsAndSelectEmailFailureType =
  '[library/email] load emails and select email failure';

export const selectEmailType = '[library/email] select email';

export const addEmailType = '[library/email] add email';
export const addEmailSuccessType = '[library/email] add email success';
export const addEmailFailureType = '[library/email] add email failure';

export const updateEmailType = '[library/email] update email';
export const updateEmailSuccessType = '[library/email] update email success';
export const updateEmailFailureType = '[library/email] update email failure';

/** Workflow */
export const loadWorkflowsType = '[library/workflow] load workflows';
export const loadWorkflowsAndSelectWorkflowType =
  '[library/workflow] load workflows and select workflow';
export const loadWorkflowsSuccessType =
  '[library/workflow] load workflows success';
export const loadWorkflowsAndSelectWorkflowSuccessType =
  '[library/workflow] load workflows and select workflow success';
export const loadWorkflowsFailureType =
  '[library/workflow] load workflows failure';
export const loadWorkflowsAndSelectWorkflowFailureType =
  '[library/workflow] load workflows and select workflow failure';

export const selectWorkflowType = '[library/workflow] select workflow';

export const addWorkflowType = '[library/workflow] add workflow';
export const addWorkflowSuccessType = '[library/workflow] add workflow success';
export const addWorkflowFailureType = '[library/workflow] add workflow failure';

export const updateWorkflowType = '[library/workflow] update workflow';
export const updateWorkflowSuccessType =
  '[library/workflow] update workflow success';
export const updateWorkflowFailureType =
  '[library/workflow] update workflow failure';

/** Automation */
export const loadAutomationsType = '[library/automation] load automations';
export const loadAutomationsAndSelectAutomationType =
  '[library/automation] load automations and select automation';
export const loadAutomationsSuccessType =
  '[library/automation] load automations success';
export const loadAutomationsAndSelectAutomationSuccessType =
  '[library/automation] load automations and select automation success';
export const loadAutomationsFailureType =
  '[library/automation] load automations failure';
export const loadAutomationsAndSelectAutomationFailureType =
  '[library/automation] load automations and select automation failure';
export const loadAutomationsStatsSuccessType =
  '[library/automation] load automations stats success';

export const selectAutomationType = '[library/automation] select automation';
export const setAutomationStateType =
  '[library/automation] set automation state';

export const addAutomationType = '[library/automation] add automation';
export const addAutomationSuccessType =
  '[library/automation] add automation success';
export const addAutomationFailureType =
  '[library/automation] add automation failure';

export const updateAutomationType = '[library/automation] update automation';
export const updateAutomationSuccessType =
  '[library/automation] update automation success';
export const updateAutomationFailureType =
  '[library/automation] update automation failure';

/** Form */
export const loadFormsType = '[library/form] load forms';
export const loadFormsAndSelectFormType =
  '[library/form] load forms and select form';
export const loadFormsSuccessType = '[library/form] load forms success';
export const loadFormsAndSelectFormSuccessType =
  '[library/form] load forms and select form success';
export const loadFormsFailureType = '[library/form] load forms failure';
export const loadFormsAndSelectFormFailureType =
  '[library/form] load forms and select form failure';

export const selectFormType = '[library/form] select form';

export const addFormType = '[library/form] add form';
export const addFormSuccessType = '[library/form] add form success';
export const addFormFailureType = '[library/form] add form failure';

export const updateFormType = '[library/form] update form';
export const updateFormSuccessType = '[library/form] update form success';
export const updateFormFailureType = '[library/form] update form failure';

/** EmailTest */
export const loadEmailTestsType = '[library/email-test] load email tests';
export const loadEmailTestsAndSelectEmailTestType =
  '[library/email] load email tests and select email test';
export const loadEmailTestsSuccessType =
  '[library/email-test] load email tests success';
export const loadEmailTestsAndSelectEmailTestSuccessType =
  '[library/email] load email tests and select email success';
export const loadEmailTestsFailureType =
  '[library/email-test] load email tests failure';
export const loadEmailTestsAndSelectEmailTestFailureType =
  '[library/email-test] load email tests and select email test failure';

export const selectEmailTestType = '[library/email-test] select email test';

/** Post */
export const loadPostsType = '[library/post] load posts';
export const loadPostsAndSelectPostType =
  '[library/post] load posts and select post';
export const loadPostsSuccessType = '[library/post] load posts success';
export const loadPostsAndSelectPostSuccessType =
  '[library/post] load posts and select post success';
export const loadPostsFailureType = '[library/post] load posts failure';
export const loadPostsAndSelectPostFailureType =
  '[library/post] load posts and select post failure';

export const selectPostType = '[library/post] select post';

export const addPostType = '[library/post] add post';
export const addPostSuccessType = '[library/post] add post success';
export const addPostFailureType = '[library/post] add post failure';

export const updatePostType = '[library/post] update post';
export const updatePostSuccessType = '[library/post] update post success';
export const updatePostFailureType = '[library/post] update post failure';

/** Tag */
export const loadTagsType = '[library/tag] load tags';
export const loadTagsAndSelectTagType =
  '[library/tag] load tags and select tag';
export const loadTagsSuccessType = '[library/tag] load tags success';
export const loadTagsAndSelectTagSuccessType =
  '[library/tag] load tags and select tag success';
export const loadTagsFailureType = '[library/tag] load tags failure';
export const loadTagsAndSelectTagFailureType =
  '[library/tag] load tags and select tag failure';

export const selectTagType = '[library/tag] select tag';

export const addTagType = '[library/tag] add tag';
export const addTagSuccessType = '[library/tag] add tag success';
export const addTagFailureType = '[library/tag] add tag failure';

export const updateTagType = '[library/tag] update tag';
export const updateTagSuccessType = '[library/tag] update tag success';
export const updateTagFailureType = '[library/tag] update tag failure';

/** Map */
export const loadMapsType = '[library/map] load map';
export const loadMapsAndSelectMapType =
  '[library/map] load maps and select map';
export const loadMapsSuccessType = '[library/map] load maps success';
export const loadMapsAndSelectMapSuccessType =
  '[library/map] load maps and select map success';
export const loadMapsFailureType = '[library/map] load maps failure';
export const loadMapsAndSelectMapFailureType =
  '[library/map] load maps and select map failure';

export const selectMapType = '[library/map] select map';

export const addMapType = '[library/map] add map';
export const addMapSuccessType = '[library/map] add map success';
export const addMapFailureType = '[library/map] add map failure';

export const updateMapType = '[library/map] update map';
export const updateMapSuccessType = '[library/map] update map success';
export const updateMapFailureType = '[library/map] update map failure';

/** Help Tag */
export const loadHelpTagsType = '[library/help-tag] load help tags';
export const loadHelpTagsSuccessType =
  '[library/help-tag] load help tags success';
export const loadHelpTagsFailureType =
  '[library/help-tag] load help tags failure';

export const loadHelpTagsAndSelectHelpTagType =
  '[library/help-tag] load help tags and select help tag';
export const loadHelpTagsAndSelectHelpTagSuccessType =
  '[library/help-tag] load help tags and select help tag success';
export const loadHelpTagsAndSelectHelpTagFailureType =
  '[library/help-tag] load help tags and select help tag failure';

export const selectHelpTagType = '[library/help-tag] select help tag';

export const addHelpTagType = '[library/help-tag] add help tag';
export const addHelpTagSuccessType = '[library/help-tag] add help tag success';
export const addHelpTagFailureType = '[library/help-tag] add help tag failure';

export const updateHelpTagType = '[library/help-tag] update help tag';
export const updateHelpTagSuccessType =
  '[library/help-tag] update help tag success';
export const updateHelpTagFailureType =
  '[library/help-tag] update help tag failure';

/** Asset */

export const loadAssetsType = '[library/asset] load assets';
export const loadAssetsSuccessType = '[library/asset] load assets success';
export const loadAssetsFailureType = '[library/asset] load assets failure';

export const reloadAssetType = '[library/asset] reload asset';
export const reloadAssetSuccessType = '[library/asset] reload asset success';
export const reloadAssetFailureType = '[library/asset] reload asset failure';

export const addAssetType = '[library/asset] add asset';
export const addAssetSuccessType = '[library/asset] add asset success';
export const addAssetFailureType = '[library/asset] add asset failure';

export const updateAssetType = '[library/asset] update asset';
export const updateAssetProgressType = '[library/asset] update asset progress';
export const updateAssetSuccessType = '[library/asset] update asset success';
export const updateAssetFailureType = '[library/asset] update asset failure';

export const deleteAssetType = '[library/asset] delete asset';
export const deleteAssetSuccessType = '[library/asset] delete asset success';
export const deleteAssetFailureType = '[library/asset] delete asset failure';

export const checkAssetIntegrationsType =
  '[library/asset] check asset integrations';
export const updateAssetIntegrationsType =
  '[library/asset] update asset integrations';
export const updateAssetIntegrationsSuccessType =
  '[library/asset] update asset integrations success';
export const updateAssetIntegrationsFailureType =
  '[library/asset] update asset integrations failure';

/** Flow */

export const loadFlowsType = '[library/flow] load flows';
export const loadFlowsSuccessType = '[library/flow] load flows success';
export const loadFlowsFailureType = '[library/flow] load flows failure';
export const loadFlowsAndSelectFlowType =
  '[library/flow] load flows and select flow';
export const loadFlowsAndSelectFlowSuccessType =
  '[library/flow] load flows and select flow success';
export const loadFlowsAndSelectFlowFailureType =
  '[library/flow] load flows and select flow failure';

export const selectFlowType = '[library/flow] select flow';

export const addFlowType = '[library/flow] add flow';
export const addFlowSuccessType = '[library/flow] add flow success';
export const addFlowFailureType = '[library/flow] add flow failure';

export const updateFlowType = '[library/flow] update flow';
export const updateFlowProgressType = '[library/flow] update flow progress';
export const updateFlowSuccessType = '[library/flow] update flow success';
export const updateFlowFailureType = '[library/flow] update flow failure';

export const deleteFlowType = '[library/flow] delete flow';
export const deleteFlowSuccessType = '[library/flow] delete flow success';
export const deleteFlowFailureType = '[library/flow] delete flow failure';

/** Course */

export const loadCoursesType = '[library/course] load courses';
export const loadCoursesSuccessType = '[library/course] load courses success';
export const loadCoursesFailureType = '[library/course] load courses failure';
export const loadCoursesAndSelectCourseType =
  '[library/course] load courses and select course';
export const loadCoursesAndSelectCourseSuccessType =
  '[library/course] load courses and select course success';
export const loadCoursesAndSelectCourseFailureType =
  '[library/course] load courses and select course failure';

export const selectCourseType = '[library/course] select course';

export const addCourseType = '[library/course] add course';
export const addCourseSuccessType = '[library/course] add course success';
export const addCourseFailureType = '[library/course] add course failure';

export const updateCourseType = '[library/course] update course';
export const updateCourseProgressType =
  '[library/course] update course progress';
export const updateCourseSuccessType = '[library/course] update course success';
export const updateCourseFailureType = '[library/course] update course failure';

export const deleteCourseType = '[library/course] delete course';
export const deleteCourseSuccessType = '[library/course] delete course success';
export const deleteCourseFailureType = '[library/course] delete course failure';

/** Challenge */

export const loadChallengesType = '[library/challenge] load challenges';
export const loadChallengesSuccessType =
  '[library/challenge] load challenges success';
export const loadChallengesFailureType =
  '[library/challenge] load challenges failure';
export const loadChallengesAndSelectChallengeType =
  '[library/challenge] load challenge and select challenge';
export const loadChallengesAndSelectChallengeSuccessType =
  '[library/challenge] load challenges and select challenge success';
export const loadChallengesAndSelectChallengeFailureType =
  '[library/challenge] load challenges and select challenge failure';

export const selectChallengeType = '[library/challenge] select challenge';

export const addChallengeType = '[library/challenge] add challenge';
export const addChallengeSuccessType =
  '[library/challenge] add challenge success';
export const addChallengeFailureType =
  '[library/challenge] add challenge failure';

export const updateChallengeType = '[library/challenge] update challenge';
export const updateChallengeProgressType =
  '[library/challenge] update challenge progress';
export const updateChallengeSuccessType =
  '[library/challenge] update challenge success';
export const updateChallengeFailureType =
  '[library/challenge] update challenge failure';

export const deleteChallengeType = '[library/challenge] delete challenge';
export const deleteChallengeSuccessType =
  '[library/challenge] delete challenge success';
export const deleteChallengeFailureType =
  '[library/challenge] delete challenge failure';

/** Content */

export const loadContentsByCourseType =
  '[library/content] load contents by course';
export const loadContentsByChallengeType =
  '[library/content] load contents by challenge';
export const loadContentsSuccessType =
  '[library/content] load contents success';
export const loadContentsFailureType =
  '[library/content] load contents failure';
export const loadContentsAndSelectContentType =
  '[library/content] load contents and select content';
export const loadContentsAndSelectContentSuccessType =
  '[library/content] load contents and select content success';
export const loadContentsAndSelectContentFailureType =
  '[library/content] load contents and select content failure';

export const selectContentType = '[library/content] select content';

export const addContentType = '[library/content] add content';
export const addContentSuccessType = '[library/content] add content success';
export const addContentFailureType = '[library/content] add content failure';

export const updateContentType = '[library/content] update content';
export const updateContentProgressType =
  '[library/content] update content progress';
export const updateContentSuccessType =
  '[library/content] update content success';
export const updateContentFailureType =
  '[library/content] update content failure';

export const deleteContentType = '[library/content] delete content';
export const deleteContentSuccessType =
  '[library/content] delete content success';
export const deleteContentFailureType =
  '[library/content] delete content failure';

/** Help */

export const loadHelpType = '[library/help] load help';
export const loadHelpSuccessType = '[library/help] load help success';
export const loadHelpFailureType = '[library/help] load help failure';
export const loadHelpAndSelectHelpType =
  '[library/help] load help and select help';
export const loadHelpAndSelectHelpSuccessType =
  '[library/help] load help and select help success';
export const loadHelpAndSelectHelpFailureType =
  '[library/help] load help and select help failure';
export const selectHelpType = '[library/help] select help';
export const addHelpType = '[library/help] add help';
export const addHelpSuccessType = '[library/help] add help success';
export const addHelpFailureType = '[library/help] add help failure';
export const updateHelpType = '[library/help] update help';
export const updateHelpSuccessType = '[library/help] update help success';
export const updateHelpFailureType = '[library/help] update help failure';
