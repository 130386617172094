import { InjectionToken } from '@angular/core';

export const APP_CONFIG = new InjectionToken<AppConfig>('app.config');
export const PLATFORM_HOST = new InjectionToken<AppConfig>('platform.host');
export const ANALYTICS_IS_SUPPORTED = new InjectionToken<AppConfig>(
  'analytics.isSupported'
);
export const FIREBASE_ANALYTICS_IS_ENABLED = new InjectionToken<AppConfig>(
  'firebase.analytics.isEnabled'
);
export const STRIPE_ENABLED = new InjectionToken<AppConfig>('stripe.enabled');

export interface AppConfig {
  updateCheckInterval: number;
  emulator?: boolean;
  recaptcha?: string;
  turnstile?: string;
  turnstileEndpoint?: string;
  firebase: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId?: string;
    measurementId?: string;
    tenantId?: string;
    authTenantId?: string;
  };
  dbHost?: string;
  chunkSize: number;
  addon?: {
    tawkTo?: {
      chatLink?: string;
      key?: string;
    };
    linkedInInsightsId?: string;
    fbPixelId?: string;
    activeCampaignId?: string;
    clarityId?: string;
    googleMeasurementId?: string;
  };
  api: {
    endpoint: string;
  };
  devToolsInstrument?: boolean;
  serviceWorkerEnabled?: boolean;
  images: {
    thumbnails: { name: string; size: { width: number; height: number } }[];
  };
  telemetry?: {
    trace?: {
      enabled: boolean;
      serviceName: string;
    };
  };
  branding?: {
    smallLogo?: string;
    siteName?: string;
    logo?: string;
    favicon?: string;
  };
  stripe?: {
    pk?: string;
    acct?: string;
  };
  affiliate?: {
    ttlDays?: number;
  };
  env?: string;
}
