export enum CoursesTooltipMap {
  coursesAddCourseButton = 'coursesAddCourseButton',
  coursesSearchBar = 'coursesSearchBar',
  coursesEditTitleField = 'coursesEditTitleField',
  coursesEditSynopsisField = 'coursesEditSynopsisField',
  coursesEditTagsField = 'coursesEditTagsField',
  coursesEditImageField = 'coursesEditImageField',
  coursesEditVideoField = 'coursesEditVideoField',
  coursesEditLayoutTab = 'coursesEditLayoutTab',
  coursesEditIntroductionTab = 'coursesEditIntroductionTab',
  coursesEditCoreContentTab = 'coursesEditCoreContentTab',
  coursesEditAddModule = 'coursesEditAddModule',
}

export const coursesTooltipMapLookups: {
  [key in CoursesTooltipMap]: { tooltip: string };
} = {
  [CoursesTooltipMap.coursesSearchBar]: {
    tooltip:
      "Looking for specific content? Type here to search across all your courses. <br/><br/> <b>Example:</b> Type 'stress management' to find related lessons",
  },
  [CoursesTooltipMap.coursesAddCourseButton]: {
    tooltip:
      "Ready to share your expertise? Click here to create a new course! Pro tip: Have your first module topic in mind before you start. <br/><br/> <b>Example:</b> Getting started with '4-Week Energy Reset for Women Over 45'",
  },
  [CoursesTooltipMap.coursesEditTitleField]: {
    tooltip:
      'Make your course title clear and compelling. What transformation are you offering? <br/><br/> <b>Example:</b> Better Sleep After 45: Natural Solutions for Restful Nights',
  },
  [CoursesTooltipMap.coursesEditSynopsisField]: {
    tooltip:
      "Paint a picture of what students will achieve. What's their life like after taking your course?<br/><br/> <b>Example:</b> Transform your sleep patterns and wake up energized without relying on caffeine",
  },
  [CoursesTooltipMap.coursesEditTagsField]: {
    tooltip:
      "Add relevant keywords to help students find your course. <br/><br/> <b>Example:</b> 'Stress Management', 'Sleep', 'Natural Solutions'",
  },
  [CoursesTooltipMap.coursesEditImageField]: {
    tooltip:
      'Select from your uploaded Assets to add a compelling course image. No image yet? Head to the Assets section first to upload one! <br/><br/> <b>Example:</b> A peaceful image of a well-rested woman starting her day',
  },
  [CoursesTooltipMap.coursesEditVideoField]: {
    tooltip:
      'Add a video that explains your course benefits and what students will learn. Upload your video in Assets first, then select it here! <br/><br/> <b>Note:</b> This is your course preview video - different from your welcome video in the course content',
  },
  [CoursesTooltipMap.coursesEditLayoutTab]: {
    tooltip:
      'Design your course flow by dragging and dropping content. Easily rearrange modules and nest content where you need it.',
  },
  [CoursesTooltipMap.coursesEditIntroductionTab]: {
    tooltip:
      'Make a great first impression! Share why you created this course and what students will achieve. This is a great place for your welcome video.',
  },
  [CoursesTooltipMap.coursesEditCoreContentTab]: {
    tooltip:
      'Create your course content here first, then add it to your modules. Think of this as your content library!',
  },
  [CoursesTooltipMap.coursesEditAddModule]: {
    tooltip:
      'Create a new section of your course. After creating content, you can add it to your modules. <br/><br/> <b>Example:</b> Week 1: Understanding Your Energy Patterns',
  },
};
