import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  CartItemProperties,
  PurchasableProperties,
  SKUProperties,
} from '@sidkik/db';
import { AppConfig, APP_CONFIG, EventsService } from '@sidkik/global';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  combineLatest,
  filter,
  map,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { CartFacade } from '../../../+state/cart/cart.facade';
import { ShopFacade } from '../../../+state/shop.facade';
import { AutoDestroy, BaseDestroyComponent } from '@sidkik/shared';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'sidkik-cart-list',
  templateUrl: './cart-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartListComponent
  extends BaseDestroyComponent
  implements OnInit, OnDestroy
{
  cartItems$!: Observable<Partial<CartItemProperties>[]>;
  subTotal$!: Observable<Partial<number>>;
  validCart$!: Observable<{ ok: boolean; error: string }>;
  @AutoDestroy()
  upsellItems$: BehaviorSubject<Partial<PurchasableProperties>[] | undefined> =
    new BehaviorSubject<Partial<PurchasableProperties>[] | undefined>([]);
  @AutoDestroy()
  upsellContent$: BehaviorSubject<
    { title: string; content: string } | undefined
  > = new BehaviorSubject<{ title: string; content: string } | undefined>(
    undefined
  );
  pSub: Subscription | undefined;

  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private cartFacade: CartFacade,
    private eventsService: EventsService,
    private shopFacade: ShopFacade,
    private titleService: Title
  ) {
    super();
  }

  ngOnInit(): void {
    this.titleService.setTitle('Cart');
    this.cartItems$ = this.cartFacade.allCartItems$.pipe(
      tap((items) => {
        for (const item of items) {
          if (
            item.data?.product.data?.upsell &&
            item.data?.product.data?.upsell.enabled
          ) {
            this.upsellContent$.next({
              title: item.data?.product.data?.upsell.header ?? '',
              content: item.data?.product.data?.upsell.content ?? '',
            });
          }
        }
      })
    );
    combineLatest([
      this.cartFacade.allCartItems$,
      this.shopFacade.allPotentialPurchasables$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([cartItems, purchasables]) => {
        for (const item of cartItems) {
          if (
            item.data?.product.data?.upsell &&
            item.data?.product.data?.upsell.enabled
          ) {
            const usItems = purchasables.filter((purchasable) =>
              item.data.product.data.upsell?.products?.includes(
                purchasable.data?.product.id ?? ''
              )
            );
            this.upsellItems$.next(usItems);

            this.upsellContent$.next({
              title: item.data?.product.data?.upsell.header ?? '',
              content: item.data?.product.data?.upsell.content ?? '',
            });
            break;
          }
        }
      });
    this.subTotal$ = this.cartFacade.allCartItems$.pipe(
      map((items) =>
        items.reduce((acc, cur) => acc + cur.data.sku.data.price, 0)
      )
    );
    this.validCart$ = this.cartFacade.allCartItems$.pipe(
      map((items) => {
        if (items.length === 0) {
          return { ok: false, error: 'Cart is empty' };
        }
        // check if recurring items have different recurring period
        const differentRecurring: Map<string, string> = new Map();
        items.forEach((item) => {
          if (item.data.sku.data.recurring?.period) {
            differentRecurring.set(
              item.data.sku.data.recurring?.period,
              item.data.sku.data.recurring?.period
            );
          }
        });
        if (differentRecurring.size > 1) {
          return {
            ok: false,
            error:
              'Unable to purchase subscriptions with different periods in the same cart. Please remove one of the items from the cart and complete the purchase before adding the other item back in.',
          };
        }
        return {
          ok: true,
          error: '',
        };
      })
    );

    this.eventsService.trackScreenView('cart', 'shop/cart');
    this.cartItems$
      .pipe(
        filter((items) => items && items.length > 0),
        take(1)
      )
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe((items) => {
        this.eventsService.viewCart(items as CartItemProperties[]);
      });
  }

  removeCartItem(item: Partial<CartItemProperties>): void {
    this.cartFacade.deleteCartItem(item as CartItemProperties);
  }

  addItemToCart(item: {
    item: Partial<PurchasableProperties>;
    sku: SKUProperties;
  }) {
    if (!item.item?.data) return;
    const cartItem: Partial<CartItemProperties> = {
      data: {
        product: item.item.data.product,
        sku: item.sku,
      },
    };
    this.cartFacade.addCartItem(cartItem);
  }
}
