import {
  ErrorHandler,
  Injectable,
  Injector,
  NgModule,
  PLATFORM_ID,
} from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  provideAnalytics,
  getAnalytics,
  Analytics,
} from '@angular/fire/analytics';
import {
  provideFirebaseApp,
  initializeApp,
  FirebaseOptions,
} from '@angular/fire/app';
import {
  AppCheck,
  CustomProvider,
  getToken,
  initializeAppCheck,
  provideAppCheck,
  ReCaptchaEnterpriseProvider,
} from '@angular/fire/app-check';
import {
  AppConfig,
  APP_CONFIG,
  GlobalModule,
  TraceService,
  SpanTypes,
  ANALYTICS_IS_SUPPORTED,
  storageEmulatorURL,
  storeLogger,
  APP_CHECK_DEBUG_TOKEN,
  FIREBASE_ANALYTICS_IS_ENABLED,
} from '@sidkik/global';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, RouterState } from '@ngrx/router-store';
import { ActionReducer, StoreModule } from '@ngrx/store';
import { AuthzModule } from '@sidkik/authz';
import { DbModule } from '@sidkik/db';
import { UIModule } from '@sidkik/ui';
import { ClientLayoutModule } from '@sidkik/client-layout';
import { RoutingModule } from './routing.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { appVersion } from './app.version';
import hash from 'object-hash';
import {
  provideStorage,
  getStorage,
  connectStorageEmulator,
} from '@angular/fire/storage';
import { getApp } from 'firebase/app';
import { appCheckError } from './services/app-check-user-error';
import { CloudflareProviderOptions } from '@sidkik/global';
import { FlushComponent } from './flush.component';
import { ShopModule } from '@sidkik/shop';

const globalErrorReporterFactory = (injector: Injector) =>
  new GlobalErrorHandler(injector);
@Injectable()
class GlobalErrorHandler implements ErrorHandler {
  traceService!: TraceService; // = inject(TraceService);
  private s = new Set<string>();

  constructor(injector: Injector) {
    // needs event tick
    setTimeout(() => {
      this.traceService = injector.get<TraceService>(TraceService);
    });
  }
  handleError(error: Error) {
    const h = hash.MD5(error);
    if (this.s.has(h)) {
      logger.error('app:app.module', 'already handled', error);
      return;
    }
    this.s.add(h);

    if (this.traceService) {
      this.traceService.startSpan(SpanTypes.errorReporter);
      this.traceService.endSpan(SpanTypes.errorReporter, error);
    }
    logger.error('app:app.module', 'global unhandled error:', error);
  }
}

export function createLogger(reducer: ActionReducer<any>): any {
  // default, no options
  return storeLogger()(reducer);
}

@NgModule({
  declarations: [AppComponent, FlushComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,

    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Full,
    }),
    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production ? [] : [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
          strictActionSerializability: true,
          // strictActionTypeUniqueness: true,
          strictActionWithinNgZone: true,
          strictStateSerializability: true,
        },
      }
    ),
    EffectsModule.forRoot([]),
    GlobalModule,
    DbModule,
    RoutingModule,
    AuthzModule.forRoot({
      authGuardFallbackURL: '/auth/login',
      authGuardLoggedInURL: '/',
      guardProtectedRoutesUntilEmailIsVerified: false,
    }),
    ClientLayoutModule,
    UIModule.forRoot(),
    ShopModule,
    // TODO: flip this to conditionally loaded - https://www.katesky.com/2020/06/22/angular-ngrx-enabling-devtools-at-runtime/
    // StoreDevtoolsModule.instrument({
    //   maxAge: 25, // Retains last 25 states
    //   logOnly: environment.production,
    // }),
    !environment.production
      ? StoreDevtoolsModule.instrument({ connectInZone: true })
      : [],
  ],
  providers: [
    {
      provide: ErrorHandler,
      useFactory: globalErrorReporterFactory,
      deps: [Injector],
    },
    provideFirebaseApp((injector: Injector) => {
      const appConfig: AppConfig = injector.get<AppConfig>(APP_CONFIG);
      const platformId: any = injector.get<any>(PLATFORM_ID);

      if (isPlatformBrowser(platformId)) {
        return initializeApp(appConfig.firebase);
      }
      // TODO: remove hardcoded
      // initialize app for server side rendering
      const epCoreProd: FirebaseOptions = {
        apiKey: 'AIzaSyCiaPZrnQn-j4eqqeEUvW6tNt5zixK5LdI',
        projectId: 'ep-core-prod',
      };
      return initializeApp(epCoreProd);
      // const smallerConfig: FirebaseOptions = {
      //   projectId: appConfig.firebase.projectId,
      //   databaseURL: 'https://' + appConfig.firebase.projectId,
      // };
      // return initializeApp(smallerConfig, appConfig.firebase.projectId);
    }),
    provideAppCheck((injector: Injector) => {
      const appConfig: AppConfig = injector.get<AppConfig>(APP_CONFIG);
      const appCheckDebugToken: AppConfig = injector.get<AppConfig>(
        APP_CHECK_DEBUG_TOKEN
      );
      const platformId: any = injector.get<any>(PLATFORM_ID);
      const traceService: TraceService =
        injector.get<TraceService>(TraceService);
      const doc: Document = injector.get<Document>(DOCUMENT);
      if (isPlatformBrowser(platformId)) {
        const app = getApp();
        if (appConfig.recaptcha) {
          try {
            (self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = appCheckDebugToken;
            logger.trace(
              'app:app.module',
              'initializing app check with key',
              appConfig.recaptcha
            );
            const appCheck = initializeAppCheck(app, {
              provider: new ReCaptchaEnterpriseProvider(appConfig.recaptcha),
              isTokenAutoRefreshEnabled: true,
            });
            logger.trace('app:app.module', 'initialized app check');
            getToken(appCheck)
              .then(() => {
                logger.info(
                  'app:app.module',
                  'successfully retrieved app check token'
                );
              })
              .catch((err: any) => {
                if (traceService) {
                  traceService.startSpan(SpanTypes.errorReporter);
                  traceService.endSpan(SpanTypes.errorReporter, err);
                }
                appCheckError(doc, traceService, appConfig);
                logger.error(
                  'app:app.module',
                  'unable to get app check token',
                  err
                );
              });

            return appCheck;
          } catch (err: any) {
            if (traceService) {
              traceService.startSpan(SpanTypes.errorReporter);
              traceService.endSpan(SpanTypes.errorReporter, err);
            }
            appCheckError(doc, traceService, appConfig);
            logger.error(
              'app:app.module',
              'unable to initialize app check',
              err
            );
          }
        }
        if (appConfig.turnstile) {
          try {
            (self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = appCheckDebugToken;
            logger.trace(
              'app:app.module',
              'initializing app check with env:',
              appConfig.turnstile,
              ' and api endpoint:',
              appConfig.api.endpoint
            );
            const cpo = new CloudflareProviderOptions(
              appConfig.turnstile,
              appConfig.api.endpoint,
              appConfig.firebase.tenantId ?? ''
            );
            const appCheck = initializeAppCheck(app, {
              provider: new CustomProvider(cpo),
              isTokenAutoRefreshEnabled: true,
            });
            logger.trace('app:app.module', 'initialized app check');
            getToken(appCheck)
              .then(() => {
                logger.info(
                  'app:app.module',
                  'successfully retrieved app check token'
                );
              })
              .catch((err: any) => {
                if (traceService) {
                  traceService.startSpan(SpanTypes.errorReporter);
                  traceService.endSpan(SpanTypes.errorReporter, err);
                }
                appCheckError(doc, traceService, appConfig);
                logger.error(
                  'app:app.module',
                  'unable to get app check token',
                  err
                );
              });

            return appCheck;
          } catch (err: any) {
            if (traceService) {
              traceService.startSpan(SpanTypes.errorReporter);
              traceService.endSpan(SpanTypes.errorReporter, err);
            }
            appCheckError(doc, traceService, appConfig);
            logger.error(
              'app:app.module',
              'unable to initialize app check',
              err
            );
          }
        }
      }
      logger.info('app:app.module', 'returning null for appcheck');
      return null as unknown as AppCheck;
    }),
    provideAnalytics((injector: Injector) => {
      const isEnabled = injector.get<boolean>(FIREBASE_ANALYTICS_IS_ENABLED);
      if (!isEnabled) {
        return {} as Analytics;
      }
      try {
        const analytics = getAnalytics();
        return analytics;
      } catch (err) {
        logger.error('app:app.module', 'unable to initialize analytics', err);
        return {} as Analytics;
      }
    }),
    provideStorage((injector: Injector) => {
      const appConfig: AppConfig = injector.get<AppConfig>(APP_CONFIG);
      const storage = getStorage();
      if (appConfig.emulator) {
        // eslint-disable-next-line no-restricted-syntax
        logger.debug(
          'app:app.module',
          `connecting storage emulator at ${storageEmulatorURL.toString()} options:`,
          appConfig.firebase
        );
        connectStorageEmulator(
          storage,
          storageEmulatorURL.hostname,
          +storageEmulatorURL.port
        );
      }
      return storage;
    }),
    { provide: 'APP_VERSION', useValue: `${appVersion}` },
    { provide: 'ADMIN_TOOL', useValue: false },
    provideClientHydration(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
