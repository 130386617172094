import { createReducer, on, Action } from '@ngrx/store';
import { globalStateKey } from '../constants/state-keys/global';
import { TrailItem } from '../services/breadcrumbs.service';

import * as GlobalActions from './global.actions';
import { NavigationType } from '../constants';

export const GLOBAL_FEATURE_KEY = globalStateKey;

export interface GlobalState {
  breadcrumbTrail?: { items: TrailItem[] }; // which Global record has been selected
  headerNavigationType?: NavigationType;
  footerNavigationType?: NavigationType;
  password?: string;
  ppk?: any;
}

export interface GlobalPartialState {
  readonly [GLOBAL_FEATURE_KEY]: GlobalState;
}

export const initialGlobalState: GlobalState = {
  breadcrumbTrail: { items: [] },
  headerNavigationType: NavigationType.Full,
  footerNavigationType: NavigationType.Full,
};

const reducer = createReducer(
  initialGlobalState,
  on(GlobalActions.addBreadcrumb, (state, trail) => ({
    ...state,
    breadcrumbTrail: { items: trail.items },
  })),
  on(GlobalActions.setHeaderNavigation, (state, nav) => ({
    ...state,
    headerNavigationType: nav.navigationType,
  })),
  on(GlobalActions.setFooterNavigation, (state, nav) => ({
    ...state,
    footerNavigationType: nav.navigationType,
  })),
  on(GlobalActions.setPassword, (state, password) => ({
    ...state,
    password: password.password,
  })),
  on(GlobalActions.setPPK, (state, ppk) => ({
    ...state,
    ppk: ppk.ppk,
  }))
);

export function globalReducer(state: GlobalState | undefined, action: Action) {
  return reducer(state, action);
}
