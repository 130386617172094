import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { filter, map, mergeMap, take, tap, catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  AppConfig,
  EntityType,
  getAutomationExecutionsByCustomerIdIndex,
  getOrdersByCustomerIdIndex,
  getParentEntityIndex,
  getSubscriptionsByCustomerIdIndex,
  HelpTenantAppMap,
  LOGGER_ENABLED,
  LOGGER_FILTER,
  MessageType,
  MOCK_ENABLED,
  PathsToMonitor,
  SpanTypes,
  TraceService,
} from '@sidkik/global';
import {
  asyncScheduler,
  BehaviorSubject,
  firstValueFrom,
  Observable,
  of,
  scheduled,
} from 'rxjs';
import { APP_CONFIG } from '@sidkik/global';
import { LocalStorageService } from './local-storage.service';
import {
  BundledHelpProperties,
  HelpProperties,
  ListenerType,
  deepCopy,
} from '../models';
import { Document } from '../db.config';
import WebWorkerPromise from 'webworker-promise';
import * as DBActions from '../+state/db.actions';
import * as GlobalActions from '@sidkik/global';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { FallbackDbWorkerService } from './fallback-db-worker.service';
import {
  onTokenChanged,
  appCheckInstance$,
  getToken,
} from '@angular/fire/app-check';
import { AutoDestroy } from '@sidkik/shared';
import { HelpService } from '@sidkik/sidkik-api';
import { PPKProperties } from '../models/ppk';
@Injectable({
  providedIn: 'root',
})
export class DbService {
  timeOutForIntegrationCheck = 60000;
  missingAppCheckInstanceCount = 0;

  @AutoDestroy()
  public commitHook$: BehaviorSubject<Document> = new BehaviorSubject<Document>(
    {} as Document
  );

  @AutoDestroy()
  public collectionHook$: BehaviorSubject<Document> =
    new BehaviorSubject<Document>({} as Document);

  @AutoDestroy()
  public documentHook$: BehaviorSubject<Document> =
    new BehaviorSubject<Document>({} as Document);

  @AutoDestroy()
  public resolvedStateItems$: BehaviorSubject<any> = new BehaviorSubject(
    undefined
  );
  @AutoDestroy()
  public resolvedChangeItems$: BehaviorSubject<any> = new BehaviorSubject(
    undefined
  );

  private userId!: string;
  private currentUser!: string;
  private worker!: WebWorkerPromise;
  private entityLastRemoteCheck: Map<EntityType, number> = new Map<
    EntityType,
    number
  >();

  private subEntityLastRemoteCheck: Map<string, number> = new Map<
    string,
    number
  >();

  @AutoDestroy()
  public ready$ = new BehaviorSubject<boolean>(false);

  dbReady = false;

  private tenantId!: string;

  constructor(
    private store: Store<any>,
    @Inject(APP_CONFIG) appConfig: AppConfig,
    @Inject('APP_VERSION') appVersion: string,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(LOGGER_ENABLED) private loggerEnabled: any,
    @Inject(LOGGER_FILTER) private loggerFilter: any,
    @Inject(MOCK_ENABLED) private mockEnabled: boolean,
    private localStorage: LocalStorageService,
    private fallbackDBWorkerService: FallbackDbWorkerService,
    private traceService: TraceService,
    @Inject(DOCUMENT) private document: Document,
    private helpService: HelpService,
    @Inject('ADMIN_TOOL') readonly isAdminTool: boolean
  ) {
    this.tenantId = appConfig.firebase.tenantId ?? '';
    if (!isPlatformServer(platformId) && typeof Worker !== 'undefined') {
      // Create a new
      this.worker = new WebWorkerPromise(
        new Worker(new URL('../worker/db.worker', import.meta.url))
      );
      this.worker.exec(MessageType.start, {
        appConfig,
        appVersion,
        loggerEnabled,
        loggerFilter,
        mockEnabled,
      });
      // load up if app check is disabled
      if (
        (!appConfig.recaptcha || appConfig.recaptcha === '') &&
        (!appConfig.turnstile || appConfig.turnstile === '')
      ) {
        logger.debug(
          'db:db.service.ts:constructor',
          'initializing worker | app check disabled'
        );

        this.worker.exec(MessageType.init).then(() => {
          logger.info(
            'db:db.service.ts:constructor',
            'worker initialized and ready'
          );
          this.dbReady = true;
          setTimeout(() => {
            this.ready$.next(true);
          });
        });
      }

      if (!isAdminTool) {
        // eslint-disable-next-line rxjs-angular/prefer-takeuntil
        this.helpService
          .getBundle()
          .pipe(take(1))
          // eslint-disable-next-line rxjs-angular/prefer-takeuntil
          .subscribe(async (bundle) => {
            // await this.dbService.loadBundle(bundle, 'tenant-help-bundle');
            await this.storeBundleLocally<BundledHelpProperties>(
              bundle,
              EntityType.BundledHelp,
              true
            );
          });
      }

      // start the app check listener
      // app check token will come from primary thread and needs to be passed to worker
      // worker cannot access dom for recaptcha token
      appCheckInstance$
        .pipe(
          filter((inst) => inst !== undefined && inst !== null),
          take(1)
        )
        .subscribe((appCheck) => {
          onTokenChanged(appCheck, (token) => {
            if (!this.dbReady) {
              logger.debug(
                'db:db.service.ts:constructor',
                'initializing db worker | app check enabled'
              );

              this.worker.exec(MessageType.init).then(() => {
                this.worker.exec(MessageType.updateAppCheckToken, {
                  token,
                });
                this.dbReady = true;
                logger.info(
                  'db:db.service.ts:constructor',
                  'worker initialized and ready'
                );
                setTimeout(() => {
                  this.ready$.next(true);
                });
              });
              return;
            }
            logger.debug(
              'db:db.service.ts:constructor',
              'primary app token changed'
            );
            this.worker.exec(MessageType.updateAppCheckToken, {
              token,
            });
          });
        });

      this.worker.on(MessageType.batchCommitted, (docs) => {
        docs.forEach((doc: any) => {
          this.commitHook$.next(doc.data);
        });
      });
      this.worker.on(MessageType.collectionChanged, (doc) => {
        this.collectionHook$.next(doc);
      });
      this.worker.on(MessageType.documentChanged, (doc) => {
        this.documentHook$.next(doc);
      });
      this.worker.on(MessageType.workerAppCheckIssue, (err) => {
        if (this.traceService) {
          this.traceService.startSpan(SpanTypes.errorReporter);
          this.traceService.endSpan(SpanTypes.errorReporter, err);
        }
      });
      this.worker.on(MessageType.workerAuthChanged, (uid: string) => {
        this.store.dispatch(
          DBActions.workerLoggedIn({
            uid,
          })
        );
      });
      this.worker.on(MessageType.refreshAppCheckToken, () => {
        logger.info(
          'db:db.service.ts:constructor',
          'refreshAppCheckToken message received'
        );
        // get a new app check token
        appCheckInstance$.pipe(take(1)).subscribe((appCheck) => {
          if (appCheck) {
            this.missingAppCheckInstanceCount = 0;
            return getToken(appCheck, true)
              .then((token) => {
                logger.info(
                  'db:db.service.ts:constructor',
                  'new app check token',
                  token
                );
                this.worker.exec(MessageType.updateAppCheckToken, {
                  token,
                });
              })
              .catch((err) => {
                logger.error(
                  'db:db.service.ts:constructor',
                  'error getting new app check token',
                  err
                );
              });
          }
          this.missingAppCheckInstanceCount++;
          logger.warn(
            'db:db.service.ts:constructor',
            'no app check instance to refresh token: missing count',
            this.missingAppCheckInstanceCount
          );
          // this should refresh the page after several failures
          if (
            this.missingAppCheckInstanceCount > 5 &&
            !isPlatformServer(this.platformId)
          ) {
            logger.warn(
              'db:db.service.ts:constructor',
              'no app check instance to refresh token: reloading page'
            );
            window.location.reload();
          }
          return;
        });
      });
    } else {
      logger.error('db:db.service.ts:constructor', 'worker not suppoted');
      this.dbReady = true;
      setTimeout(() => {
        this.ready$.next(true);
      });
      // Web workers are not supported in this environment.
      // You should add a fallback so that your program still executes correctly.
    }
  }

  public loadBundle(bundle: ArrayBuffer, namedQuery: string) {
    this.worker.exec(MessageType.loadBundle, { bundle, namedQuery });
  }

  public async storeBundleLocally<T>(
    bundle: ArrayBuffer,
    entityType: EntityType,
    overwrite = false
  ) {
    const objects = this.parseBundleData<T>(bundle);
    if (overwrite) {
      await this.localStorage.clear(entityType);
    }
    await firstValueFrom(
      this.localStorage.upsertBundleDocs(objects, entityType)
    );
  }

  public getContextualHelp(
    helpMap: HelpTenantAppMap
  ): Observable<HelpProperties | null> {
    logger.trace('db:db.service.ts:getContextualHelp', 'helpMap', helpMap);
    return this.localStorage.getContextualHelp(helpMap).pipe(
      map((help) => {
        logger.trace('db:db.service.ts:getContextualHelp', 'help', help);
        if (help.length > 0) {
          logger.trace(
            'db:db.service.ts:getContextualHelp',
            'help[0]',
            help[0]
          );
          return help[0] as HelpProperties;
        }
        return null;
      }),
      catchError((error) => {
        logger.error(
          'db:db.service.ts:getContextualHelp',
          'Error retrieving contextual help:',
          error
        );
        return of(null); // Return a safe fallback value
      })
    );
  }

  /**
   * Parses a Firestore bundle received as an ArrayBuffer into an array of documents.
   *
   * The bundle format from Go consists of length-prefixed JSON objects:
   * [length1]{json1}[length2]{json2}...
   * where each length is the byte length of the following JSON object.
   *
   * The process:
   * 1. Decode ArrayBuffer to string to parse JSON and find object boundaries
   * 2. Use TextEncoder to calculate byte lengths to match Go's byte lengths
   *    (needed because JS strings are UTF-16 but Go uses UTF-8)
   * 3. Extract documents and return their data fields
   *
   * @param bundleData - ArrayBuffer containing the bundle data from Firestore
   * @returns Array of parsed document data
   */
  public parseBundleData<T>(bundleData: ArrayBuffer): T[] {
    // Convert ArrayBuffer to string for JSON parsing
    const decoder = new TextDecoder();
    const text = decoder.decode(bundleData);
    // Need encoder to calculate UTF-8 byte lengths to match Go
    const encoder = new TextEncoder();

    const objects: T[] = [];
    let currentPos = 0;

    while (currentPos < text.length) {
      // Find the length prefix (numbers before the JSON object)
      const lengthEndPos = text.indexOf('{', currentPos);
      if (lengthEndPos === -1) break;

      // Parse the length prefix - this is the UTF-8 byte length from Go
      const expectedByteLength = parseInt(
        text.substring(currentPos, lengthEndPos)
      );

      // Find the end of this JSON object by checking byte lengths
      // We need to do this because JS string length != UTF-8 byte length
      let jsonEnd = lengthEndPos + 1; // Start after the opening {
      let currentJson = '';
      while (jsonEnd <= text.length) {
        currentJson = text.substring(lengthEndPos, jsonEnd);
        const actualByteLength = encoder.encode(currentJson).length;
        if (actualByteLength === expectedByteLength) {
          break;
        }
        jsonEnd++;
      }

      try {
        const obj = JSON.parse(currentJson);
        if (obj.document?.fields?.data) {
          // Extract the actual data from the fields property
          objects.push(obj.document.fields as T);
        }
        // Handle metadata and named queries if needed
        if (obj.namedQuery) {
          logger.debug(
            'db:db.service.ts:parseBundleData',
            'namedQuery',
            obj.namedQuery
          );
        }
        if (obj.metadata) {
          logger.debug(
            'db:db.service.ts:parseBundleData',
            'metadata',
            obj.metadata
          );
        }
      } catch (e) {
        logger.error(
          'db:db.service.ts:parseBundleData',
          'Failed to parse bundle object:',
          e,
          currentJson
        );
      }

      currentPos = jsonEnd;
    }

    logger.debug('db:db.service.ts:parseBundleData', 'objects', objects);

    return objects;
  }

  public setUser(user: string) {
    this.userId = user;
    this.currentUser = user;
    this.localStorage
      .get<PPKProperties>(EntityType.PPK, user)
      .pipe(take(1))
      .subscribe((ppk) => {
        if (ppk) {
          this.store.dispatch(GlobalActions.setPPK({ ppk }));
        }
      });
    if (this.worker) {
      this.worker.exec(MessageType.setUser, { user });
    }
  }

  public getAllEntities<T>(entityType: EntityType): Observable<T[]> {
    if (!isPlatformServer(this.platformId) && typeof Worker !== 'undefined') {
      return scheduled(
        this.worker.exec(MessageType.getAllEntities, { entityType }),
        asyncScheduler
      ).pipe(
        tap(() => this.entityLastRemoteCheck.set(entityType, Date.now())),
        mergeMap(() => this.localStorage.getAll<T>(entityType))
      );
    }
    return scheduled(
      this.fallbackDBWorkerService.getAllRemote<T>(entityType),
      asyncScheduler
    );
  }

  public async tidyOnSignout() {
    if (!isPlatformServer(this.platformId) && typeof Worker !== 'undefined') {
      await this.localStorage.clearEntries();
    }
  }

  public async debugStartOver() {
    if (!isPlatformServer(this.platformId) && typeof Worker !== 'undefined') {
      try {
        await this.localStorage.startOver();
        // go to home page
        window.location.href = '/';
      } catch (error) {
        logger.error('db:db.service.ts:debugStartOver', error);
      }
    }
  }

  public getAllSubEntities<T>(
    entityType: EntityType,
    parentEntityType: EntityType,
    parentEntityId: string,
    grandParentEntityType?: EntityType,
    grandParentEntityId?: string
  ): Observable<T[]> {
    if (!isPlatformServer(this.platformId) && typeof Worker !== 'undefined') {
      const key = entityType + parentEntityId;
      return scheduled(
        this.worker.exec(MessageType.getAllSubEntities, {
          entityType,
          parentEntityType,
          parentEntityId,
          grandParentEntityType,
          grandParentEntityId,
        }),
        asyncScheduler
      ).pipe(
        tap(() => this.subEntityLastRemoteCheck.set(key, Date.now())),
        mergeMap(() =>
          // check if customer parent - on local, customer is not nested
          {
            switch (true) {
              case parentEntityType === EntityType.Customer &&
                entityType === EntityType.Order:
                return this.localStorage.getAllByIndex<T>(
                  entityType,
                  getOrdersByCustomerIdIndex(), // switch to entity type for minimized order - client only loads itself, tenant loads multiples
                  parentEntityId
                );
              case parentEntityType === EntityType.Customer &&
                entityType === EntityType.Subscription:
                return this.localStorage.getAllByIndex<T>(
                  entityType,
                  getSubscriptionsByCustomerIdIndex(), // switch to entity type for minimized order - client only loads itself, tenant loads multiples
                  parentEntityId
                );
              case parentEntityType === EntityType.Customer &&
                entityType === EntityType.AutomationExecution:
                return this.localStorage.getAllByIndex<T>(
                  entityType,
                  getAutomationExecutionsByCustomerIdIndex(),
                  parentEntityId
                );
              case parentEntityType === EntityType.CustomerParent:
                return this.localStorage.getAll<T>(entityType);
              case entityType === EntityType.MinimizedOrder:
                return this.localStorage.getAllByIndex<T>(
                  entityType,
                  getParentEntityIndex(entityType), // switch to entity type for minimized order - client only loads itself, tenant loads multiples
                  parentEntityId
                );
              default:
                return this.localStorage.getAllByIndex<T>(
                  entityType,
                  getParentEntityIndex(parentEntityType),
                  parentEntityId
                );
            }
          }
        )
      );
    }
    return scheduled(
      this.fallbackDBWorkerService.getAllNestedRemote<T>(
        entityType,
        parentEntityType,
        parentEntityId
      ),
      asyncScheduler
    );
  }

  public getEntity<T>(
    entityType: EntityType,
    id: string
  ): Observable<T | undefined> {
    if (!isPlatformServer(this.platformId) && typeof Worker !== 'undefined') {
      return scheduled(
        this.worker.exec(MessageType.getEntity, { entityType, id }),
        asyncScheduler
      ).pipe(
        tap(() => this.entityLastRemoteCheck.set(entityType, Date.now())),
        mergeMap(() => this.localStorage.get<T>(entityType, id))
      );
    }
    return scheduled(
      this.fallbackDBWorkerService.getRemote<T>(entityType, id),
      asyncScheduler
    );
  }

  public getSubEntity<T>(
    entityType: EntityType,
    id: string,
    parentEntityType: EntityType,
    parentEntityId: string
  ): Observable<T | undefined> {
    if (!isPlatformServer(this.platformId) && typeof Worker !== 'undefined') {
      return scheduled(
        this.worker.exec(MessageType.getSubEntity, {
          entityType,
          id,
          parentEntityType,
          parentEntityId,
        }),
        asyncScheduler
      ).pipe(
        tap(() => this.entityLastRemoteCheck.set(entityType, Date.now())),
        mergeMap(() => this.localStorage.get<T>(entityType, id))
      );
    }
    return scheduled(
      this.fallbackDBWorkerService.getNestedRemote<T>(
        entityType,
        id,
        parentEntityType,
        parentEntityId
      ),
      asyncScheduler
    );
  }

  public monitorCollection(pathToMonitor: string): void {
    this.worker
      .exec(MessageType.monitorRemoteCollection, { pathToMonitor })
      .then((data) =>
        logger.debug(
          'db:db.service.ts:monitorCollection',
          'starting collection monitor: ',
          pathToMonitor
        )
      );
  }

  public monitorDocumentPerpetually(
    pathToMonitor: string,
    listenerType: ListenerType
  ): void {
    this.worker
      .exec(MessageType.monitorRemoteDocumentPerpetually, {
        pathToMonitor,
        listenerType,
      })
      .then((data) =>
        logger.debug(
          'db:db.service.ts:monitorDocumentPerpetually',
          'starting perpetual monitor: ',
          pathToMonitor,
          listenerType
        )
      );
  }

  public monitorDocument(
    entityType: EntityType,
    id: string,
    pathToMonitor: string,
    valuesToFind: any[],
    lastUpdate: number,
    updatedField = PathsToMonitor.IntegrationUpdatedField,
    waitTime = 60000,
    parentEntityType?: EntityType,
    parentEntityId?: string
  ): void {
    if (!isPlatformServer(this.platformId) && typeof Worker !== 'undefined') {
      logger.debug(
        'db:db.service.ts:monitorDocument',
        'calling worker MessageType.monitorRemoteDocument',
        id
      );
      this.worker
        .exec(MessageType.monitorRemoteDocument, {
          entityType,
          id,
          pathToMonitor,
          valuesToFind,
          lastUpdate,
          updatedField,
          waitTime,
          parentEntityType,
          parentEntityId,
        })
        .then((data) => {
          logger.debug(
            'db:db.service.ts:monitorDocument',
            'got response worker MessageType.monitorRemoteDocument',
            data
          );
          this.store.dispatch(
            DBActions.monitorDocumentSuccess({
              entityType,
              id,
              pathToMonitor,
              valuesToFind,
              lastUpdate,
              parentEntityType,
              parentEntityId,
              data,
            })
          );
        })
        .catch((error) => {
          logger.error(
            'db:db.service.ts:monitorDocument',
            'got error worker MessageType.monitorRemoteDocument',
            error
          );
          this.store.dispatch(
            DBActions.monitorDocumentFailure({
              entityType,
              id,
              pathToMonitor,
              valuesToFind,
              lastUpdate,
              parentEntityType,
              parentEntityId,
              error: error.message,
            })
          );
        });
    }
  }

  public deleteDoc(
    data: Document,
    parentEntityType?: EntityType,
    parentEntityId?: string
  ): Observable<void> {
    if (!isPlatformServer(this.platformId) && typeof Worker !== 'undefined') {
      // create a copy so that the worker/firestore doesn't hang up on any dangling objects
      // can cause erratic behaviour of stored objects
      const copy = deepCopy(data);
      copy.deleteFlag = true;
      return this.localStorage.delete(copy).pipe(
        take(1),
        mergeMap(() => {
          return this.worker.exec(MessageType.requestSync, {
            data: copy,
            parentEntityType,
            parentEntityId,
          });
        })
      );
    }
    return of();
  }

  public setDoc(
    data: Document,
    parentEntityType?: EntityType,
    parentEntityId?: string | null | undefined,
    merge = true // this is set to not overwrite the integrations for file based updates like products, skus, etc
  ): Observable<void> {
    if (!isPlatformServer(this.platformId) && typeof Worker !== 'undefined') {
      // create a copy so that the worker/firestore doesn't hang up on any dangling objects
      // can cause erratic behaviour of stored objects
      const copy = deepCopy(data);
      copy.mergeFlag = merge;
      if (!copy.tenant || copy.tenant == '') {
        copy.tenant = this.tenantId;
      }
      return this.localStorage.upsert(copy).pipe(
        take(1),
        mergeMap(() => {
          return this.worker.exec(MessageType.requestSync, {
            data: copy,
            parentEntityType,
            parentEntityId,
          });
        })
      );
    }

    return of();
  }

  /** set doc only in local repo
   *
   */
  public setDocLocalOnly(data: Document): Observable<void> {
    if (!isPlatformServer(this.platformId) && typeof Worker !== 'undefined') {
      const copy = deepCopy(data);
      return this.localStorage.upsert(copy);
    }

    return of();
  }

  /** delete doc only in local repo
   *
   */
  public deleteDocLocalOnly(data: Document): Observable<void> {
    if (!isPlatformServer(this.platformId) && typeof Worker !== 'undefined') {
      const copy = deepCopy(data);
      return this.localStorage.delete(copy);
    }

    return of();
  }

  /** get doc only in local repo
   *
   */
  public getDocsLocalOnly<T>(entityType: EntityType): Observable<T[]> {
    if (!isPlatformServer(this.platformId) && typeof Worker !== 'undefined') {
      return this.localStorage.getAll<T>(entityType);
    }

    return of();
  }

  public getDbUser() {
    return this.userId;
  }

  public getTenantId() {
    return this.tenantId;
  }

  public getNowTimestamp(): number {
    return new Date().getTime();
  }

  public getUserAuditId(): string {
    return this.currentUser;
  }

  public clearLocalCache(): Promise<void> {
    return this.localStorage.clearAllEntries();
  }
}
