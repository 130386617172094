export enum AutomationNodeType {
  Trigger = 'trigger',
  Start = 'start',
  Activity = 'activity',
  Conditional = 'conditional',
  Wait = 'wait',
  Unknown = 'unknown',
}

export enum AutomationTriggerType {
  Event = 'event',
}

export enum AutomationActivityType {
  SlackMessage = 'slack_message',
  SendEmail = 'send_email',
  ProvisionPlatformTenant = 'provision_platform_tenant',
  ProvisionPlatformTenantTrial = 'provision_platform_tenant_trial',
  AddTag = 'add_tag',
  RemoveTag = 'remove_tag',
  AddList = 'add_list',
  RemoveList = 'remove_list',
  CancelTrialWait = 'cancel_trial_wait',
  ConvertTrialToPaid = 'convert_trial_to_paid',
  AddPermissions = 'add_permissions',
  RemovePermissions = 'remove_permissions',
}

export enum AutomationConditionalType {
  Binary = 'binary',
  Multi = 'multi',
}

export enum AutomationLogicalOperator {
  And = 'and',
  Or = 'or',
}

export enum AutomationFilterNames {
  Course = 'Course',
  Challenge = 'Challenge',
  Email = 'Email',
  Campaign = 'Campaign',
  CustomerTag = 'CustomerTag',
  List = 'CustomerList',
  Product = 'Product',
  Form = 'Form',
  FormCheckBox = 'FormCheckBox',
}

export enum AutomationFilterTargets {
  Course = 'courseId',
  Challenge = 'challengeId',
  Email = 'email',
  Campaign = 'campaign',
}

export enum AutomationWaitScale {
  Second = 'Second',
  Minute = 'Minute',
  Hour = 'Hour',
  Day = 'Day',
  Week = 'Week',
}
