import { Storage, StorageProperties } from '../storage';
import { Meta } from '../meta';
import { EntityType } from '@sidkik/global';

export interface PPKData {
  privateKey: JsonWebKey;
  publicKey: JsonWebKey;
  wrappedGroupKeyShare: number[];
}

export interface PPKProperties extends StorageProperties {
  data: PPKData;
  meta: Meta;
  id: string;
}

export class PPK extends Storage implements PPKProperties {
  public override data!: PPKData;

  constructor(options?: PPKProperties, user?: string) {
    super(options, user, EntityType.PPK);
    this.update(options?.data);
  }

  public update(data?: PPKData): void {
    this.data = { ...data } as unknown as PPKData;
  }
}
