export enum AssetsTooltipMap {
  assetSearchBar = 'assetSearchBar',
  assetsAddAssetButton = 'assetsAddAssetButton',
  assetsListTableFileColumn = 'assetsListTableFileColumn',
  assetsListTableTypeColumn = 'assetsListTableTypeColumn',
  assetsListTableStatusColumn = 'assetsListTableStatusColumn',
  assetsListTableActionMenu = 'assetsListTableActionMenu',
  assetsListTableActionCopySmallImageUrl = 'assetsListTableActionCopySmallImageUrl',
  assetsListTableActionCopyMediumImageUrl = 'assetsListTableActionCopyMediumImageUrl',
  assetsListTableActionCopyLargeImageUrl = 'assetsListTableActionCopyLargeImageUrl',
  assetsListTableActionDeleteImage = 'assetsListTableActionDeleteImage',
  assetsListTableActionSyncDomainsToVimeo = 'assetsListTableActionSyncDomainsToVimeo',
  assetsListTableActionPushToVimeo = 'assetsListTableActionPushToVimeo',
  assetsListTableActionCopyVimeoEmbed = 'assetsListTableActionCopyVimeoEmbed',
  assetsListTableActionCopyVimeoPreviewImage = 'assetsListTableActionCopyVimeoPreviewImage',
  assetsListTableActionCopyVimeoId = 'assetsListTableActionCopyVimeoId',
  assetsListTableActionCopyVimeoUrl = 'assetsListTableActionCopyVimeoUrl',
  assetsListTableActionCopyPDFUrl = 'assetsListTableActionCopyPDFUrl',
}

export const assetsTooltipMapLookups: {
  [key in AssetsTooltipMap]: { tooltip: string };
} = {
  [AssetsTooltipMap.assetSearchBar]: {
    tooltip:
      "Looking for something specific? Type any part of your file name here - like 'banner' or 'course1' - and watch the magic happen! Pro tip: Good file names make searching a breeze. <br/><br/> <b>Example:</b> Typing 'hero' finds 'homepage-hero.jpg', 'about-hero-banner.png', etc.",
  },
  [AssetsTooltipMap.assetsAddAssetButton]: {
    tooltip:
      "Drop files right on this screen or click here to choose them from your computer. Like magic, we'll handle the rest! No more questioning if your file made it - you'll see the progress right here. <br/><br/> <b>Example:</b> Upload multiple files at once - perfect for adding all your course thumbnails in one go!",
  },
  [AssetsTooltipMap.assetsListTableFileColumn]: {
    tooltip:
      "This is what you named your file - make it count! Think 'course1-intro-video.mp4' instead of 'video1.mp4'. Your future self will thank you! <br/><br/> <b>Example:</b> 'homepage-hero-banner.jpg' tells you exactly what this image is for",
  },
  [AssetsTooltipMap.assetsListTableTypeColumn]: {
    tooltip:
      "What kind of file you're looking at - like image/jpg for photos or application/pdf for downloadable worksheets. Don't worry about remembering these; we handle the technical stuff! <br/><br/> <b>Example:</b> 'image/png' means it's a PNG image, great for graphics with transparent backgrounds",
  },
  [AssetsTooltipMap.assetsListTableStatusColumn]: {
    tooltip:
      "Track your file's journey from upload to ready-to-use. When you see 'done', you're good to go! If you see anything else, we're still working our magic. <br/><br/> <b>Example:</b> 'done' means this file is ready to use in your courses or website",
  },
  [AssetsTooltipMap.assetsListTableActionMenu]: {
    tooltip:
      "Click these three dots for some handy shortcuts! Copy different image sizes for various uses, or remove files you don't need anymore. It's like a tiny command center for each file. <br/><br/> <b>Example:</b> Copy the Medium URL for perfect blog images, or the Large URL for stunning hero banners",
  },
  [AssetsTooltipMap.assetsListTableActionCopySmallImageUrl]: {
    tooltip:
      "Perfect for thumbnails and icons. We've optimized this smaller version to look great without slowing things down. <br/><br/> <b>Example:</b> Great for course lesson thumbnails or sidebar images",
  },
  [AssetsTooltipMap.assetsListTableActionCopyMediumImageUrl]: {
    tooltip:
      "Your go-to for most website images. It's the Goldilocks of sizes - not too big, not too small, just right!Your go-to for most website images. It's the Goldilocks of sizes - not too big, not too small, just right! <br/><br/> <b>Example:</b> Ideal for blog post images or team photos",
  },
  [AssetsTooltipMap.assetsListTableActionCopyLargeImageUrl]: {
    tooltip:
      'When you need to make a big impression! Perfect for hero banners or full-width images that need to look crispy clean. <br/><br/> <b>Example:</b> Use for homepage hero images or full-screen backgrounds',
  },
  [AssetsTooltipMap.assetsListTableActionDeleteImage]: {
    tooltip:
      "Ready to say goodbye to this file? Click here to remove it. Don't worry - we'll double-check before anything's permanently deleted!<br/><br/> <b>Example:</b> Cleaning up old versions of files you don't need anymore",
  },
  [AssetsTooltipMap.assetsListTableActionSyncDomainsToVimeo]: {
    tooltip:
      'If you are having issues displaying your video due to domain restrictions, you can sync your domains to Vimeo to fix this issue.',
  },
  [AssetsTooltipMap.assetsListTableActionPushToVimeo]: {
    tooltip:
      'You can push your video to Vimeo if for some reason it is not displaying on your website or the status is not showing vimeo in blue.',
  },
  [AssetsTooltipMap.assetsListTableActionCopyVimeoEmbed]: {
    tooltip:
      'You can copy the embed code of your video to display it on your website.',
  },
  [AssetsTooltipMap.assetsListTableActionCopyVimeoPreviewImage]: {
    tooltip:
      'You can copy the preview image of your video to display it on your website.',
  },
  [AssetsTooltipMap.assetsListTableActionCopyVimeoId]: {
    tooltip: 'You can copy the vimeo id of your video.',
  },
  [AssetsTooltipMap.assetsListTableActionCopyVimeoUrl]: {
    tooltip:
      'You can copy the vimeo url of your video. Paste this into a post or content to embed your video.',
  },
  [AssetsTooltipMap.assetsListTableActionCopyPDFUrl]: {
    tooltip:
      'You can copy the pdf url of your pdf. Use this as a link to download the pdf.',
  },
};
