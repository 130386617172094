import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GLOBAL_FEATURE_KEY, GlobalState } from './global.reducer';
import { authzStateKey } from '../constants';

export const getGlobalState =
  createFeatureSelector<GlobalState>(GLOBAL_FEATURE_KEY);

export const getBreadcrumbTrail = createSelector(
  getGlobalState,
  (state: GlobalState) => state.breadcrumbTrail
);

export const getAuthzState = createFeatureSelector<any>(authzStateKey);

export const getUserId = createSelector(
  getAuthzState,
  (state: any) => state.userId
);

export const getPPK = createSelector(
  getGlobalState,
  (state: GlobalState) => state.ppk
);

export const getJWT = createSelector(
  getAuthzState,
  (state: any) => state.idToken?.token
);

export const getPassword = createSelector(
  getGlobalState,
  (state: GlobalState) => state.password
);

export const getMe = createSelector(getAuthzState, (state: any) => state.me);

export const getHeaderNavigation = createSelector(
  getGlobalState,
  (state: GlobalState) => state.headerNavigationType
);

export const getFooterNavigation = createSelector(
  getGlobalState,
  (state: GlobalState) => state.footerNavigationType
);
