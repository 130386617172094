import { Inject, Injectable } from '@angular/core';
import { GlobalFacade } from '../+state/global.facade';
import { filter, take } from 'rxjs';
import { APP_CONFIG, AppConfig } from '../models';
import { IndexedDBService } from '@sidkik/indexed-db';
import { EntityType } from '../constants';

interface KeyMaterial {
  privateKey: CryptoKey;
  publicKey: CryptoKey;
  wrappedGroupKeyShare: ArrayBuffer;
}

interface SerializedKeyMaterial {
  privateKey: JsonWebKey;
  publicKey: JsonWebKey;
  wrappedGroupKeyShare: number[];
}

interface StoredKeyMaterial {
  id: string;
  data: SerializedKeyMaterial;
}

@Injectable({
  providedIn: 'root',
})
export class ClientCryptoService {
  private keyMaterial: KeyMaterial | null = null;
  private readonly STORE_NAME = EntityType.PPK;

  constructor(
    private readonly globalFacade: GlobalFacade,
    @Inject(APP_CONFIG) private readonly appConfig: AppConfig
  ) {
    this.globalFacade.password$
      .pipe(
        filter(
          (password) =>
            password !== undefined && password !== null && password !== ''
        ),
        take(1)
      )
      .subscribe((password) => {
        this.globalFacade.me$
          .pipe(
            filter((me) => me !== undefined && me !== null && me !== ''),
            take(1)
          )
          .subscribe(async (me) => {
            await this.deriveKeys(
              me.id,
              password ?? '',
              this.appConfig.firebase.tenantId ?? ''
            );
            if (!this.keyMaterial) {
              logger.error(
                'global:client-crypto:deriveKeys',
                'No key material'
              );
            }
            if (this.keyMaterial) {
              const serialized = await this.serializeKeyMaterial(
                this.keyMaterial
              );
              const serializedPPK = {
                id: me.id,
                data: { ...serialized, id: me.id },
              };
              this.globalFacade.requestPPKStorage(serializedPPK);
            }
          });
      });
  }

  // Derive keys from user credentials
  async deriveKeys(
    userId: string,
    password: string,
    tenant: string
  ): Promise<void> {
    // Create a deterministic salt from userId and tenant
    const salt = await this.generateSalt(userId, tenant);

    // Derive a master key using PBKDF2
    const masterKey = await this.deriveMasterKey(password, salt);

    // Derive key pair from master key
    const { privateKey, publicKey } = await this.deriveKeyPair(masterKey);

    // Store key material in memory (never in localStorage/sessionStorage)
    this.keyMaterial = {
      privateKey,
      publicKey,
      wrappedGroupKeyShare: new ArrayBuffer(0), // Will be set when joining group
    };
  }

  // Generate deterministic salt from user info
  private async generateSalt(
    userId: string,
    tenant: string
  ): Promise<ArrayBuffer> {
    const encoder = new TextEncoder();
    const data = encoder.encode(`${tenant}:${userId}`);
    return await window.crypto.subtle.digest('SHA-256', data);
  }

  // Derive master key using PBKDF2
  private async deriveMasterKey(
    password: string,
    salt: ArrayBuffer
  ): Promise<CryptoKey> {
    const encoder = new TextEncoder();
    const passwordBuffer = encoder.encode(password);

    const baseKey = await window.crypto.subtle.importKey(
      'raw',
      passwordBuffer,
      'PBKDF2',
      false,
      ['deriveBits', 'deriveKey']
    );

    return await window.crypto.subtle.deriveKey(
      {
        name: 'PBKDF2',
        salt,
        iterations: 100000,
        hash: 'SHA-256',
      },
      baseKey,
      { name: 'AES-GCM', length: 256 },
      true,
      ['encrypt', 'decrypt']
    );
  }

  // Derive RSA key pair from master key
  private async deriveKeyPair(
    masterKey: CryptoKey
  ): Promise<{ privateKey: CryptoKey; publicKey: CryptoKey }> {
    // Generate RSA key pair
    const keyPair = (await window.crypto.subtle.generateKey(
      {
        name: 'RSA-OAEP',
        modulusLength: 2048,
        publicExponent: new Uint8Array([1, 0, 1]),
        hash: 'SHA-256',
      },
      true,
      ['encrypt', 'decrypt', 'wrapKey', 'unwrapKey']
    )) as CryptoKeyPair;

    return {
      privateKey: keyPair.privateKey,
      publicKey: keyPair.publicKey,
    };
  }

  // Store encrypted group key share
  async storeGroupKeyShare(encryptedShare: ArrayBuffer): Promise<void> {
    if (!this.keyMaterial) {
      throw new Error('Keys not initialized');
    }
    this.keyMaterial.wrappedGroupKeyShare = encryptedShare;
  }

  // Decrypt content locally
  async decryptContent(
    encryptedContent: ArrayBuffer,
    contentId: string,
    metadata: { creatorId: string; createdAt: number }
  ): Promise<ArrayBuffer> {
    if (!this.keyMaterial) {
      throw new Error('Keys not initialized');
    }

    try {
      // Unwrap group key share
      const groupKeyShare = await window.crypto.subtle.unwrapKey(
        'raw',
        this.keyMaterial.wrappedGroupKeyShare,
        this.keyMaterial.privateKey,
        { name: 'RSA-OAEP' },
        { name: 'AES-GCM' },
        true,
        ['encrypt', 'decrypt']
      );

      // Use group key share to decrypt content
      const iv = this.deriveIV(contentId);
      return await window.crypto.subtle.decrypt(
        {
          name: 'AES-GCM',
          iv,
        },
        groupKeyShare,
        encryptedContent
      );
    } catch (err) {
      console.error('Content decryption failed:', err);
      throw new Error('Failed to decrypt content');
    }
  }

  // Derive deterministic IV for content
  private deriveIV(contentId: string): ArrayBuffer {
    const encoder = new TextEncoder();
    return encoder.encode(contentId.slice(0, 12)); // AES-GCM needs 12 bytes IV
  }

  // Handle password change
  async changePassword(
    userId: string,
    tenant: string,
    oldPassword: string,
    newPassword: string
  ): Promise<void> {
    if (!this.keyMaterial) {
      throw new Error('Keys not initialized');
    }

    // Store the current wrapped group key share
    const currentShare = this.keyMaterial.wrappedGroupKeyShare;

    // Re-derive keys with new password
    await this.deriveKeys(userId, newPassword, tenant);

    // Restore the wrapped group key share
    this.keyMaterial.wrappedGroupKeyShare = currentShare;
  }

  // Clear keys from memory on logout
  clearKeys(): void {
    this.keyMaterial = null;
  }

  // Serialize key material for storage
  private async serializeKeyMaterial(
    keyMaterial: KeyMaterial
  ): Promise<SerializedKeyMaterial> {
    const privateJwk = await window.crypto.subtle.exportKey(
      'jwk',
      keyMaterial.privateKey
    );
    const publicJwk = await window.crypto.subtle.exportKey(
      'jwk',
      keyMaterial.publicKey
    );

    // Convert ArrayBuffer to number array for JSON serialization
    const wrappedGroupKeyShare = Array.from(
      new Uint8Array(keyMaterial.wrappedGroupKeyShare)
    );

    return {
      privateKey: privateJwk,
      publicKey: publicJwk,
      wrappedGroupKeyShare,
    };
  }

  // Deserialize key material from storage
  private async deserializeKeyMaterial(
    serialized: SerializedKeyMaterial
  ): Promise<KeyMaterial> {
    const privateKey = await window.crypto.subtle.importKey(
      'jwk',
      serialized.privateKey,
      {
        name: 'RSA-OAEP',
        hash: 'SHA-256',
      },
      true,
      ['decrypt', 'unwrapKey']
    );

    const publicKey = await window.crypto.subtle.importKey(
      'jwk',
      serialized.publicKey,
      {
        name: 'RSA-OAEP',
        hash: 'SHA-256',
      },
      true,
      ['encrypt', 'wrapKey']
    );

    // Convert number array back to ArrayBuffer
    const wrappedGroupKeyShare = new Uint8Array(serialized.wrappedGroupKeyShare)
      .buffer;

    return {
      privateKey,
      publicKey,
      wrappedGroupKeyShare,
    };
  }
}
