import { Component, inject, Input, OnInit } from '@angular/core';
import {
  AllHelpTooltipMaps,
  HelpContextualTooltipService,
} from '@sidkik/global';
import {} from '@sidkik/global';

@Component({
  selector: 'sidkik-help-context-tooltip',
  template: `
    <sidkik-icon
      [sidkikTooltip]="tooltip"
      [showDelay]="500"
      class="tw-block tw-ml-1 -tw-mt-[2px] tw-h-3 tw-w-3"
      svgIcon="tooltip_question"
    ></sidkik-icon>
  `,
})
export class HelpContextTooltipComponent implements OnInit {
  @Input() tooltipKey!: AllHelpTooltipMaps;

  private helpContextualTooltipService = inject(HelpContextualTooltipService);
  tooltip = '';

  ngOnInit(): void {
    const t = this.helpContextualTooltipService.getTooltip(this.tooltipKey);
    if (t) {
      this.tooltip = t.tooltip;
    }
  }
}
