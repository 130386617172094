export enum ChallengesTooltipMap {
  challengesAddChallengeButton = 'challengesAddChallengeButton',
  challengesSearchBar = 'challengesSearchBar',
  challengesEditTitleField = 'challengesEditTitleField',
  challengesEditSynopsisField = 'challengesEditSynopsisField',
  challengesEditTagsField = 'challengesEditTagsField',
  challengesEditImageField = 'challengesEditImageField',
  challengesEditVideoField = 'challengesEditVideoField',
  challengesEditLayoutTab = 'challengesEditLayoutTab',
  challengesEditIntroductionTab = 'challengesEditIntroductionTab',
  challengesEditCoreContentTab = 'challengesEditCoreContentTab',
  challengesEditSentimentTab = 'challengesEditSentimentTab',
  challengesEditSentimentNewButton = 'challengesEditSentimentNewButton',
  challengesEditSentimentNameField = 'challengesEditSentimentNameField',
  challengesEditSentimentDescriptionField = 'challengesEditSentimentDescriptionField',
  challengesEditSentimentHintField = 'challengesEditSentimentHintField',
  challengesEditAddModule = 'challengesEditAddModule',
  challengesEditMultipleInstancesToggle = 'challengesEditMultipleInstancesToggle',
  challengesEditNumberOfActivitiesField = 'challengesNumberOfActivitiesField',
  challengesEditFixedChallengeStartField = 'challengesEditFixedChallengeStartField',
  challengesEditAllowChallengeCategoryToggle = 'challengesEditAllowChallengeCategoryToggle',
  challengesEditDurationInDaysField = 'challengesEditDurationInDaysField',
}

export const challengesTooltipMapLookups: {
  [key in ChallengesTooltipMap]: { tooltip: string };
} = {
  [ChallengesTooltipMap.challengesEditSentimentHintField]: {
    tooltip:
      'Short description of sentiment. This is shown as the hint in the chart for the sentiment.<br/><br/> <b>Example:</b> Morning Energy Level',
  },
  [ChallengesTooltipMap.challengesSearchBar]: {
    tooltip:
      "Looking for specific content? Type here to search across all your challenges. <br/><br/> <b>Example:</b> Type 'stress management' to find related challenges",
  },
  [ChallengesTooltipMap.challengesAddChallengeButton]: {
    tooltip:
      "Ready to share your expertise? Click here to create a new challenge! <br/><br/> <b>Example:</b> Getting started with '10-Day Mindfulness Challenge'",
  },
  [ChallengesTooltipMap.challengesEditTitleField]: {
    tooltip:
      "This is your challenge's headline - make it catchy and clear! Something like '30-Day Fitness Jumpstart' tells people exactly what they're signing up for. Think scroll-stopping but honest! <br/><br/> <b>Example:</b> 1Transform Your Strength: 30-Day Bodyweight Challenge",
  },
  [ChallengesTooltipMap.challengesEditSynopsisField]: {
    tooltip:
      'Paint a picture of the transformation ahead! What will participants achieve? How will they feel? Keep it short but mighty - like a movie trailer for your challenge.<br/><br/> <b>Example:</b> Transform your fitness in just 30 days with daily workouts and nutrition tips designed for busy folks. No gym required!',
  },
  [ChallengesTooltipMap.challengesEditTagsField]: {
    tooltip:
      "Help your perfect participants find you! Add relevant tags like 'fitness', 'beginner-friendly', or 'no equipment needed'. Think: What would your ideal participant search for?<br/><br/> <b>Example:</b> fitness, home workout, 30-day challenge, beginner-friendly",
  },
  [ChallengesTooltipMap.challengesEditImageField]: {
    tooltip:
      'First impressions count! Upload an inspiring image that represents your challenge. Think: What would motivate someone to join? Keep it clean, clear, and on-brand. <br/><br/> <b>Example:</b> Action shot of someone doing a home workout or a before/after transformation',
  },
  [ChallengesTooltipMap.challengesEditVideoField]: {
    tooltip:
      "Nothing sells like you! Record a quick intro about your challenge - what it is, who it's for, and why you're excited about it. Keep it natural and enthusiastic! <br/><br/> <b>Example:</b> 2-minute welcome video explaining the challenge journey",
  },
  [ChallengesTooltipMap.challengesEditLayoutTab]: {
    tooltip:
      'Design how your challenge flows. Think about the journey - what should participants see first? How should activities build on each other? Make it logical and motivating!',
  },
  [ChallengesTooltipMap.challengesEditIntroductionTab]: {
    tooltip:
      "Welcome your participants! Set expectations, share any prep work needed, and get them excited about what's ahead. Think of it as your virtual high-five!",
  },
  [ChallengesTooltipMap.challengesEditCoreContentTab]: {
    tooltip:
      'This is where your expertise shines! Add your activities, instructions, and resources. Break things down into clear, actionable steps that set participants up for success.',
  },
  [ChallengesTooltipMap.challengesEditAddModule]: {
    tooltip:
      'Create a new section of your challenge. After creating content, you can add it to your modules. <br/><br/> <b>Example:</b> Day 1: Boost Your Energy',
  },
  [ChallengesTooltipMap.challengesEditSentimentTab]: {
    tooltip:
      'Create custom tracking points for your participants. What matters most for their journey? Energy levels? Water intake? Mood? These daily check-ins keep everyone engaged and aware of their progress!',
  },
  [ChallengesTooltipMap.challengesEditSentimentNewButton]: {
    tooltip:
      'Create custom tracking points for your participants. What matters most for their journey? Energy levels? Water intake? Mood? These daily check-ins keep everyone engaged and aware of their progress!',
  },
  [ChallengesTooltipMap.challengesEditSentimentNameField]: {
    tooltip:
      'Name your tracking point something clear and specific. Think: What would make sense in a daily check-in question?<br/><br/> <b>Example:</b> Morning Energy Level',
  },
  [ChallengesTooltipMap.challengesEditSentimentDescriptionField]: {
    tooltip:
      "Help participants understand exactly what they're tracking. Be specific! For example: 'Rate your energy levels compared to yesterday, considering how you feel right now.'<br/><br/> <b>Example:</b> Compare your current energy level to yesterday's: feeling worse, same, or better?",
  },
  [ChallengesTooltipMap.challengesEditFixedChallengeStartField]: {
    tooltip:
      'Want everyone to start together? Toggle this on for community challenges where the magic happens in shared experiences. Leave it off for self-paced flexibility!',
  },
  [ChallengesTooltipMap.challengesEditAllowChallengeCategoryToggle]: {
    tooltip:
      'Let participants select a subcategory or difficulty level within your challenge. This provides a more personalized experience and is useful for challenges that cater to different skill levels or interests.<br/><br/> <b>Example:</b> Mind, Body, Spirit',
  },
  [ChallengesTooltipMap.challengesEditMultipleInstancesToggle]: {
    tooltip:
      "Can folks repeat your challenge? Toggle 'yes' for habits they might want to build again (like a fitness reset) or 'no' for one-time transformative experiences",
  },
  [ChallengesTooltipMap.challengesEditNumberOfActivitiesField]: {
    tooltip:
      'How many distinct activities will your participants complete? This helps them see the full journey ahead. For a 30-day challenge, you might have 30 activities - one per day!',
  },
  [ChallengesTooltipMap.challengesEditDurationInDaysField]: {
    tooltip:
      'Total length of the challenge in days. Consider the number of activities and the time commitment you expect from participants.',
  },
};
