import { AssetsTooltipMap, assetsTooltipMapLookups } from './areas/assets';
import { CoursesTooltipMap, coursesTooltipMapLookups } from './areas/courses';
import {
  ChallengesTooltipMap,
  challengesTooltipMapLookups,
} from './areas/challenges';
export type AllHelpTooltipMaps =
  | AssetsTooltipMap
  | CoursesTooltipMap
  | ChallengesTooltipMap;
export const AllHelpTooltipMaps = {
  ...AssetsTooltipMap,
  ...CoursesTooltipMap,
  ...ChallengesTooltipMap,
} as const;

export const HelpToolTipMap = {
  ...assetsTooltipMapLookups,
  ...coursesTooltipMapLookups,
  ...challengesTooltipMapLookups,
};
