import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import {
  createTransaction,
  optionsGenerator,
  validateBeforeTransaction,
  openDatabase,
  CreateObjectStore,
  DeleteObjectStore,
  CONFIG_TOKEN,
  DBConfig,
  Key,
  RequestEvent,
  ObjectStoreMeta,
  DBMode,
  WithID,
} from './indexed-db';
import { Observable, Subject, combineLatest, from } from 'rxjs';
import { take } from 'rxjs/operators';
import { isPlatformServer } from '@angular/common';
import { AutoDestroy } from '@sidkik/shared';

@Injectable()
export class IndexedDBService {
  private indexedDB!: IDBFactory;

  constructor(
    @Inject(CONFIG_TOKEN) private dbConfig: DBConfig,
    @Inject(PLATFORM_ID) private platformId?: any,
    @Inject('readonly_access') private readonly roAccess = false
  ) {
    if (isPlatformServer(this.platformId) ?? false) return;
    if (!dbConfig.name) {
      throw new Error(
        'IndexedDB: Please, provide the dbName in the configuration'
      );
    }
    if (!dbConfig.version) {
      throw new Error(
        'IndexedDB: Please, provide the db version in the configuration'
      );
    }

    this.indexedDB =
      window?.indexedDB ||
      (window as any)?.mozIndexedDB ||
      (window as any)?.webkitIndexedDB ||
      (window as any)?.msIndexedDB ||
      indexedDB;

    if (!this.roAccess) {
      CreateObjectStore(
        this.indexedDB,
        dbConfig.name,
        dbConfig.version,
        dbConfig.objectStoresMeta,
        dbConfig.migrationFactory
      );
    }

    openDatabase(this.indexedDB, dbConfig.name).then((db) => {
      if (db.version !== dbConfig.version) {
        if (process.env['NODE_ENV'] !== 'production') {
          logger.warn(
            'indexeddb:indexed-db.service',
            `Your DB Config doesn't match the most recent version of the DB with name ${this.dbConfig.name}, please update it
          DB current version: ${db.version};
          Your configuration: ${dbConfig.version};
          `
          );
        }
        this.dbConfig.version = db.version;
      }
    });
  }

  /**
   * Allows to crate a new object store ad-hoc
   * @param storeName The name of the store to be created
   * @param migrationFactory The migration factory if exists
   */
  createObjectStore(
    storeSchema: ObjectStoreMeta,
    migrationFactory?: () => {
      [key: number]: (db: IDBDatabase, transaction: IDBTransaction) => void;
    }
  ): void {
    const storeSchemas: ObjectStoreMeta[] = [storeSchema];
    CreateObjectStore(
      this.indexedDB,
      this.dbConfig.name,
      ++this.dbConfig.version,
      storeSchemas,
      migrationFactory
    );
  }

  /**
   * Adds new entry in the store and returns its key
   * @param storeName The name of the store to add the item
   * @param value The entry to be added
   * @param key The optional key for the entry
   */
  add<T>(storeName: string, value: T, key?: any): Observable<T & WithID> {
    return new Observable((obs) => {
      openDatabase(this.indexedDB, this.dbConfig.name, this.dbConfig.version)
        .then((db: IDBDatabase) => {
          const transaction = createTransaction(
            db,
            optionsGenerator(DBMode.readwrite, storeName, (e) => obs.error(e))
          );
          const objectStore = transaction.objectStore(storeName);
          const request: IDBRequest<IDBValidKey> = key
            ? objectStore.add(value, key)
            : objectStore.add(value);

          request.onsuccess = async (evt: Event) => {
            const result: any = (evt.target as IDBOpenDBRequest).result;
            const getRequest: IDBRequest = objectStore.get(
              result
            ) as IDBRequest<T>;
            getRequest.onsuccess = (event: Event) => {
              obs.next((event.target as IDBRequest<T & WithID>).result);
              obs.complete();
            };
          };
        })
        .catch((error) => obs.error(error));
    });
  }

  /**
   * Adds new entries in the store and returns its key
   * @param storeName The name of the store to add the item
   * @param values The entries to be added containing optional key attribute
   */
  bulkAdd<T>(
    storeName: string,
    values: Array<T & { key?: any }>
  ): Observable<number[]> {
    const promises = new Promise<number[]>((resolve, reject) => {
      openDatabase(this.indexedDB, this.dbConfig.name, this.dbConfig.version)
        .then((db: IDBDatabase) => {
          const transaction = createTransaction(
            db,
            optionsGenerator(DBMode.readwrite, storeName, resolve, reject)
          );
          const objectStore = transaction.objectStore(storeName);

          const results = values.map((value) => {
            return new Promise<number>((resolve1, reject1) => {
              const key = value.key;
              delete value.key;

              const request: IDBRequest<IDBValidKey> = key
                ? objectStore.add(value, key)
                : objectStore.add(value);

              request.onsuccess = (evt: Event) => {
                const result = (evt.target as IDBOpenDBRequest).result;
                resolve1(result as unknown as number);
              };
            });
          });

          resolve(Promise.all(results));
        })
        .catch((reason) => reject(reason));
    });

    return from(promises);
  }

  /**
   * Delete entries in the store and returns current entries in the store
   * @param storeName The name of the store to add the item
   * @param keys The keys to be deleted
   */
  bulkDelete(storeName: string, keys: Key[]): Observable<number[]> {
    const promises = keys.map((key) => {
      return new Promise<number>((resolve, reject) => {
        openDatabase(this.indexedDB, this.dbConfig.name, this.dbConfig.version)
          .then((db: IDBDatabase) => {
            const transaction = createTransaction(
              db,
              optionsGenerator(DBMode.readwrite, storeName, reject, resolve)
            );
            const objectStore = transaction.objectStore(storeName);
            objectStore.delete(key);

            transaction.oncomplete = () => {
              this.getAll(storeName)
                .pipe(take(1))
                .subscribe((newValues) => {
                  resolve(newValues as any);
                });
            };
          })
          .catch((reason) => reject(reason));
      });
    });
    return from(Promise.all(promises));
  }

  /**
   * Returns entry by key.
   * @param storeName The name of the store to query
   * @param key The entry key
   */
  getByKey<T>(storeName: string, key: IDBValidKey): Observable<T> {
    return new Observable<T>((obs) => {
      openDatabase(this.indexedDB, this.dbConfig.name, this.dbConfig.version)
        .then((db: IDBDatabase) => {
          const transaction = createTransaction(
            db,
            optionsGenerator(DBMode.readonly, storeName, obs.error)
          );
          const objectStore = transaction.objectStore(storeName);
          const request = objectStore.get(key) as IDBRequest<T>;
          request.onsuccess = (event: Event) => {
            obs.next((event.target as IDBRequest<T>).result);
            obs.complete();
          };
          request.onerror = (event: Event) => {
            obs.error(event);
          };
        })
        .catch((error) => obs.error(error));
    });
  }

  /**
   * Retrieve multiple entries in the store
   * @param storeName The name of the store to retrieve the items
   * @param keys The ids entries to be retrieve
   */
  bulkGet<T>(storeName: string, keys: Array<IDBValidKey>): any {
    const observables = keys.map((key) => this.getByKey(storeName, key));

    return new Observable((obs) => {
      combineLatest(observables).subscribe((values) => {
        obs.next(values);
        obs.complete();
      });
    });
  }

  /**
   * Returns entry by id.
   * @param storeName The name of the store to query
   * @param id The entry id
   */
  getByID<T>(storeName: string, id: string | number): Observable<T> {
    return new Observable((obs) => {
      openDatabase(this.indexedDB, this.dbConfig.name, this.dbConfig.version)
        .then((db: IDBDatabase) => {
          validateBeforeTransaction(db, storeName, (e) => obs.error(e));
          const transaction = createTransaction(
            db,
            optionsGenerator(DBMode.readonly, storeName, obs.error, obs.next)
          );
          const objectStore = transaction.objectStore(storeName);
          const request: IDBRequest = objectStore.get(id) as IDBRequest<T>;
          request.onsuccess = (event: Event) => {
            obs.next((event.target as IDBRequest<T>).result);
          };
        })
        .catch((error) => obs.error(error));
    });
  }

  /**
   * Returns entry by index.
   * @param storeName The name of the store to query
   * @param indexName The index name to filter
   * @param key The entry key.
   */
  getByIndex<T>(
    storeName: string,
    indexName: string,
    key: IDBValidKey
  ): Observable<T> {
    return new Observable((obs) => {
      openDatabase(this.indexedDB, this.dbConfig.name, this.dbConfig.version)
        .then((db) => {
          validateBeforeTransaction(db, storeName, (e) => obs.error(e));
          const transaction = createTransaction(
            db,
            optionsGenerator(DBMode.readonly, storeName, obs.error)
          );
          const objectStore = transaction.objectStore(storeName);
          const index = objectStore.index(indexName);
          const request = index.get(key) as IDBRequest<T>;
          request.onsuccess = (event: Event) => {
            obs.next((event.target as IDBRequest<T>).result);
            obs.complete();
          };
        })
        .catch((reason) => obs.error(reason));
    });
  }

  /**
   * Return all elements from one store
   * @param storeName The name of the store to select the items
   */
  getAll<T>(storeName: string): Observable<T[]> {
    return new Observable((obs) => {
      openDatabase(this.indexedDB, this.dbConfig.name, this.dbConfig.version)
        .then((db) => {
          validateBeforeTransaction(db, storeName, (e) => obs.error(e));
          const transaction = createTransaction(
            db,
            optionsGenerator(DBMode.readonly, storeName, obs.error, obs.next)
          );
          const objectStore = transaction.objectStore(storeName);

          const request: IDBRequest = objectStore.getAll();

          request.onerror = (evt: Event) => {
            obs.error(evt);
          };

          (request.onsuccess as unknown) = ({
            target: { result: ResultAll },
          }: RequestEvent<T>) => {
            obs.next(ResultAll as T[]);
            obs.complete();
          };
        })
        .catch((error) => obs.error(error));
    });
  }

  /**
   * Return all elements from one store and remove them
   * @param storeName The name of the store to select the items
   */
  dequeueAll<T>(storeName: string): Observable<T[]> {
    return new Observable((obs) => {
      openDatabase(this.indexedDB, this.dbConfig.name, this.dbConfig.version)
        .then((db) => {
          validateBeforeTransaction(db, storeName, (e) => obs.error(e));
          const transaction = createTransaction(
            db,
            optionsGenerator(DBMode.readwrite, storeName, obs.error, obs.next)
          );
          const objectStore = transaction.objectStore(storeName);

          const request: IDBRequest = objectStore.getAll();

          request.onerror = (evt: Event) => {
            obs.error(evt);
          };

          (request.onsuccess as unknown) = ({
            target: { result: ResultAll },
          }: RequestEvent<T>) => {
            const clearResp = objectStore.clear();
            clearResp.onsuccess = () => transaction.commit();
            obs.next(ResultAll as T[]);
            obs.complete();
          };
        })
        .catch((error) => obs.error(error));
    });
  }

  /**
   * Adds or updates a record in store with the given value and key. Return all items present in the store
   * @param storeName The name of the store to update
   * @param value The new value for the entry
   */
  update<T>(storeName: string, value: T): Observable<T> {
    return new Observable((obs) => {
      openDatabase(this.indexedDB, this.dbConfig.name, this.dbConfig.version)
        .then((db) => {
          validateBeforeTransaction(db, storeName, (e) => obs.error(e));
          const transaction = createTransaction(
            db,
            optionsGenerator(DBMode.readwrite, storeName, (e) => obs.error(e))
          );
          const objectStore = transaction.objectStore(storeName);

          const request: IDBRequest<IDBValidKey> = objectStore.put(value);

          request.onsuccess = async (evt: Event) => {
            const result: any = (evt.target as IDBOpenDBRequest).result;

            const getRequest: IDBRequest = objectStore.get(
              result
            ) as IDBRequest<T>;
            getRequest.onsuccess = (event: Event) => {
              obs.next((event.target as IDBRequest<T & WithID>).result);
              obs.complete();
            };
          };
        })
        .catch((reason) => obs.error(reason));
    });
  }

  /**
   * Adds or updates entries in the store and returns its key
   * @param storeName The name of the store to add the item
   * @param values The entries to be added containing optional key attribute
   */
  bulkUpdate<T>(
    storeName: string,
    values: Array<T & { key?: any }>
  ): Observable<number[]> {
    const promises = new Promise<number[]>((resolve, reject) => {
      openDatabase(this.indexedDB, this.dbConfig.name, this.dbConfig.version)
        .then((db: IDBDatabase) => {
          const transaction = createTransaction(
            db,
            optionsGenerator(DBMode.readwrite, storeName, resolve, reject)
          );
          const objectStore = transaction.objectStore(storeName);

          const results = values.map((value) => {
            return new Promise<number>((resolve1, reject1) => {
              const request: IDBRequest<IDBValidKey> = objectStore.put(value);

              request.onsuccess = (evt: Event) => {
                const result = (evt.target as IDBOpenDBRequest).result;
                resolve1(result as unknown as number);
              };
            });
          });

          resolve(Promise.all(results));
        })
        .catch((reason) => reject(reason));
    });

    return from(promises);
  }

  /**
   * Returns all items from the store after delete.
   * @param storeName The name of the store to have the entry deleted
   * @param key The key of the entry to be deleted
   */
  delete<T>(storeName: string, key: Key): Observable<T[]> {
    return new Observable((obs) => {
      openDatabase(this.indexedDB, this.dbConfig.name, this.dbConfig.version)
        .then((db) => {
          validateBeforeTransaction(db, storeName, (e) => obs.error(e));
          const transaction = createTransaction(
            db,
            optionsGenerator(DBMode.readwrite, storeName, (e) => obs.error(e))
          );
          const objectStore = transaction.objectStore(storeName);
          objectStore.delete(key);

          transaction.oncomplete = () => {
            this.getAll(storeName)
              .pipe(take(1))
              .subscribe((newValues) => {
                obs.next(newValues as T[]);
                obs.complete();
              });
          };
        })
        .catch((reason) => obs.error(reason));
    });
  }

  /**
   * Returns true from the store after a successful delete.
   * @param storeName The name of the store to have the entry deleted
   * @param key The key of the entry to be deleted
   */
  deleteByKey(storeName: string, key: Key): Observable<boolean> {
    return new Observable((obs) => {
      openDatabase(this.indexedDB, this.dbConfig.name, this.dbConfig.version)
        .then((db) => {
          validateBeforeTransaction(db, storeName, (e) => obs.error(e));
          const transaction = createTransaction(
            db,
            optionsGenerator(DBMode.readwrite, storeName, (e) => obs.error(e))
          );
          const objectStore = transaction.objectStore(storeName);

          transaction.oncomplete = () => {
            obs.next(true);
            obs.complete();
          };

          objectStore.delete(key);
        })
        .catch((reason) => obs.error(reason));
    });
  }

  /**
   * Returns true if successfully delete all entries from the store.
   * @param storeName The name of the store to have the entries deleted
   */
  clear(storeName: string): Observable<boolean> {
    return new Observable((obs) => {
      openDatabase(this.indexedDB, this.dbConfig.name, this.dbConfig.version)
        .then((db) => {
          validateBeforeTransaction(db, storeName, (e) => obs.error(e));
          const transaction = createTransaction(
            db,
            optionsGenerator(DBMode.readwrite, storeName, (e) => obs.error(e))
          );
          const objectStore = transaction.objectStore(storeName);
          objectStore.clear();
          transaction.oncomplete = () => {
            obs.next(true);
            obs.complete();
          };
        })
        .catch((reason) => obs.error(reason));
    });
  }

  /**
   * Returns true if successfully delete the DB.
   */
  deleteDatabase(): Observable<boolean> {
    return new Observable((obs) => {
      openDatabase(this.indexedDB, this.dbConfig.name, this.dbConfig.version)
        .then(async (db) => {
          // Listen for the versionchange event to close all open connections
          db.onversionchange = () => {
            db.close();
            console.log('Database connection closed due to version change');
          };

          await db.close();

          const deleteDBRequest = this.indexedDB.deleteDatabase(
            this.dbConfig.name
          );

          deleteDBRequest.onsuccess = () => {
            obs.next(true);
            obs.complete();
          };

          deleteDBRequest.onerror = (error) => obs.error(error);

          deleteDBRequest.onblocked = () => {
            console.warn(
              `Deletion of database ${this.dbConfig.name} is blocked. Forcing close of all connections.`
            );
            // Force close all connections by triggering a version change
            const forceCloseRequest = this.indexedDB.open(
              this.dbConfig.name,
              this.dbConfig.version + 1
            );
            forceCloseRequest.onsuccess = (event) => {
              const forceCloseDb = (event.target as IDBOpenDBRequest).result;
              forceCloseDb.close();
              // Retry deleting the database after forcing close
              const retryDeleteDBRequest = this.indexedDB.deleteDatabase(
                this.dbConfig.name
              );
              retryDeleteDBRequest.onsuccess = () => {
                obs.next(true);
                obs.complete();
              };
              retryDeleteDBRequest.onerror = (error) => obs.error(error);
              retryDeleteDBRequest.onblocked = () => {
                obs.error(
                  new Error(`Unable to delete database because it's blocked`)
                );
              };
            };
          };
        })
        .catch((error) => obs.error(error));
    });
  }

  /**
   * Returns the open cursor event
   * @param storeName The name of the store to have the entries deleted
   * @param keyRange The key range which the cursor should be open on
   */
  openCursor(storeName: string, keyRange?: IDBKeyRange): Observable<Event> {
    return new Observable((obs) => {
      openDatabase(this.indexedDB, this.dbConfig.name, this.dbConfig.version)
        .then((db) => {
          validateBeforeTransaction(db, storeName, (e) => obs.error(e));
          const transaction = createTransaction(
            db,
            optionsGenerator(DBMode.readonly, storeName, obs.error)
          );
          const objectStore = transaction.objectStore(storeName);
          const request =
            keyRange === undefined
              ? objectStore.openCursor()
              : objectStore.openCursor(keyRange);

          request.onsuccess = (event: Event) => {
            obs.next(event);
            obs.complete();
          };
        })
        .catch((reason) => obs.error(reason));
    });
  }

  /**
   * Open a cursor by index filter.
   * @param storeName The name of the store to query.
   * @param indexName The index name to filter.
   * @param keyRange The range value and criteria to apply on the index.
   */
  openCursorByIndex(
    storeName: string,
    indexName: string,
    keyRange: IDBKeyRange,
    mode: DBMode = DBMode.readonly,
    direction: IDBCursorDirection = 'next'
  ): Observable<Event | undefined> {
    // eslint-disable-next-line sidkik-angular/subject-autodestroy
    const obs = new Subject<Event | undefined>();

    openDatabase(this.indexedDB, this.dbConfig.name, this.dbConfig.version)
      .then((db) => {
        validateBeforeTransaction(db, storeName, (reason) => {
          obs.error(reason);
        });
        const transaction = createTransaction(
          db,
          optionsGenerator(
            mode,
            storeName,
            (reason) => {
              obs.error(reason);
            },
            () => {
              obs.next(undefined);
            }
          )
        );
        const objectStore = transaction.objectStore(storeName);
        const index = objectStore.index(indexName);
        const request = index.openCursor(keyRange, direction);

        request.onsuccess = (event: Event) => {
          obs.next(event);
        };
      })
      .catch((reason) => obs.error(reason));

    return obs;
  }

  /**
   * Returns all items by an index.
   * @param storeName The name of the store to query
   * @param indexName The index name to filter
   * @param keyRange  The range value and criteria to apply on the index.
   */
  getAllByIndex<T>(
    storeName: string,
    indexName: string,
    keyRange: IDBKeyRange
  ): Observable<T[]> {
    const data: T[] = [];
    return new Observable((obs) => {
      openDatabase(this.indexedDB, this.dbConfig.name, this.dbConfig.version)
        .then((db) => {
          validateBeforeTransaction(db, storeName, (e) => obs.error(e));
          const transaction = createTransaction(
            db,
            optionsGenerator(DBMode.readonly, storeName, obs.error)
          );
          const objectStore = transaction.objectStore(storeName);
          const index = objectStore.index(indexName);
          const request = index.openCursor(keyRange);
          request.onsuccess = (event) => {
            const cursor: IDBCursorWithValue = (
              event.target as IDBRequest<IDBCursorWithValue>
            ).result;
            if (cursor) {
              data.push(cursor.value);
              cursor.continue();
            } else {
              obs.next(data);
              obs.complete();
            }
          };
        })
        .catch((reason) => {
          obs.error(reason);
        });
    });
  }

  /**
   * Returns all primary keys by an index.
   * @param storeName The name of the store to query
   * @param indexName The index name to filter
   * @param keyRange  The range value and criteria to apply on the index.
   */
  getAllKeysByIndex(
    storeName: string,
    indexName: string,
    keyRange: IDBKeyRange
  ): Observable<{ primaryKey: any; key: any }[]> {
    const data: { primaryKey: any; key: any }[] = [];
    return new Observable((obs) => {
      openDatabase(this.indexedDB, this.dbConfig.name, this.dbConfig.version)
        .then((db) => {
          validateBeforeTransaction(db, storeName, (e) => obs.error(e));
          const transaction = createTransaction(
            db,
            optionsGenerator(DBMode.readonly, storeName, obs.error)
          );
          const objectStore = transaction.objectStore(storeName);
          const index = objectStore.index(indexName);
          const request = index.openKeyCursor(keyRange);
          request.onsuccess = (event) => {
            const cursor: IDBCursor = (event.target as IDBRequest<IDBCursor>)
              .result;
            if (cursor) {
              data.push({ primaryKey: cursor.primaryKey, key: cursor.key });
              cursor.continue();
            } else {
              obs.next(data);
              obs.complete();
            }
          };
        })
        .catch((reason) => obs.error(reason));
    });
  }

  /**
   * Returns the number of rows in a store.
   * @param storeName The name of the store to query
   * @param keyRange  The range value and criteria to apply.
   */
  count(
    storeName: string,
    keyRange?: IDBValidKey | IDBKeyRange
  ): Observable<number> {
    return new Observable((obs) => {
      openDatabase(this.indexedDB, this.dbConfig.name, this.dbConfig.version)
        .then((db) => {
          validateBeforeTransaction(db, storeName, (e) => obs.error(e));
          const transaction = createTransaction(
            db,
            optionsGenerator(DBMode.readonly, storeName, obs.error)
          );
          const objectStore = transaction.objectStore(storeName);
          const request: IDBRequest = objectStore.count(keyRange);
          request.onerror = (e) => obs.error(e);
          request.onsuccess = (e) => {
            obs.next(
              (e.target as IDBOpenDBRequest).result as unknown as number
            );
            obs.complete();
          };
        })
        .catch((reason) => obs.error(reason));
    });
  }

  /**
   * Returns the number of rows in a store.
   * @param storeName The name of the store to query
   * @param keyRange  The range value and criteria to apply.
   */
  countByIndex(
    storeName: string,
    indexName: string,
    keyRange?: IDBValidKey | IDBKeyRange
  ): Observable<number> {
    return new Observable((obs) => {
      openDatabase(this.indexedDB, this.dbConfig.name, this.dbConfig.version)
        .then((db) => {
          validateBeforeTransaction(db, storeName, (e) => obs.error(e));
          const transaction = createTransaction(
            db,
            optionsGenerator(DBMode.readonly, storeName, obs.error)
          );
          const objectStore = transaction.objectStore(storeName);
          const index = objectStore.index(indexName);
          const request: IDBRequest = index.count(keyRange);
          request.onerror = (e) => obs.error(e);
          request.onsuccess = (e) => {
            obs.next(
              (e.target as IDBOpenDBRequest).result as unknown as number
            );
            obs.complete();
          };
        })
        .catch((reason) => obs.error(reason));
    });
  }

  /**
   * Delete the store by name.
   * @param storeName The name of the store to query
   */
  deleteObjectStore(storeName: string): Observable<boolean> {
    return DeleteObjectStore(
      this.dbConfig.name,
      ++this.dbConfig.version,
      storeName
    );
  }
}
