import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  inject,
  Input,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs';
import { TabComponent } from './tab.component';
import {
  CdkDragDrop,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import {
  AllHelpTooltipMaps,
  HelpContextualTooltipService,
} from '@sidkik/global';

@Component({
  selector: 'sidkik-tabs',
  templateUrl: './tabs.component.html',
})
export class TabsComponent implements AfterContentInit {
  @Input() vertical = false;
  @Input() dndEnabled = false;

  @ContentChildren(TabComponent) tabs!: QueryList<TabComponent>;

  @Output() orderChanged = new EventEmitter<TabComponent[]>();

  selectedTab!: TabComponent;

  @ViewChild(CdkDropList) dropList!: CdkDropList;

  private helpContextualTooltipService = inject(HelpContextualTooltipService);

  constructor(private route: ActivatedRoute, private cdr: ChangeDetectorRef) {}

  ngAfterContentInit() {
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      let tabToSelect = this.tabs.first;
      if (params['tab']) {
        tabToSelect =
          this.tabs.find((t) => t.title === params['tab']) ?? tabToSelect;
      }
      this.selectTab(tabToSelect);
    });

    if (this.dropList) {
      this.dropList.enterPredicate = () => true;
    }
  }

  getHelpContextualTooltip(tooltipKey: AllHelpTooltipMaps) {
    return this.helpContextualTooltipService.getTooltip(tooltipKey)?.tooltip;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  selected() {}

  selectFirstIfNoneSelected() {
    if (!this.selectedTab) {
      this.selectFirstTab();
    }
  }

  selectFirstTab() {
    this.selectTab(this.tabs.first);
  }

  selectTab(tab: TabComponent | any) {
    if (!tab) {
      return;
    }
    this.selectedTab = tab;
    // deactivate all tabs
    this.tabs.toArray().forEach((tab) => {
      tab.active = false;
      tab.markForCheck();
    });

    // activate the tab the user has clicked on.
    tab.active = true;
    tab.markForCheck();
    this.cdr.detectChanges();
  }

  onTabDrop(event: CdkDragDrop<any[]>) {
    if (event.previousIndex === event.currentIndex) {
      return;
    }

    const tabs = this.tabs.toArray();
    moveItemInArray(tabs, event.previousIndex, event.currentIndex);
    this.tabs.reset(tabs);

    this.cdr.detectChanges();

    // Emit the new order
    this.orderChanged.emit(tabs);
  }
}
