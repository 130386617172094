import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { actionButtonActionText, ActionService } from '@sidkik/global';
import { NotificationService } from '../../../services/notification.service';

interface ActionButtonContent {
  actionId: string;
  actionText: string;
  actionDisplay: string;
  actionAlignment: string;
}

@Component({
  selector: 'sidkik-editor-js-action-button',
  templateUrl: './editor-js-action-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorJsActionButtonComponent {
  @Input() content!: ActionButtonContent;
  constructor(
    private readonly actionService: ActionService,
    private readonly notificationService: NotificationService
  ) {}

  launchAction() {
    this.actionService.launchAction(this.content.actionId);
    const { title, message } = actionButtonActionText[this.content.actionId];
    setTimeout(() => {
      this.notificationService.showInfo(title, message);
    }, 1000);
  }
}
