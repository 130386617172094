import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import {
  AppConfig,
  APP_CONFIG,
  HTTPTraceHeader,
  TraceService,
  SpanTypes,
} from '@sidkik/global';
import { Observable, catchError, tap, throwError } from 'rxjs';

export interface HelpAPI {
  generateBundle(trace?: HTTPTraceHeader): Observable<void>;
  getBundle(trace?: HTTPTraceHeader): Observable<ArrayBuffer>;
}

@Injectable({
  providedIn: 'root',
})
export class HelpService implements HelpAPI {
  constructor(
    @Inject(APP_CONFIG) readonly tenantConfig: AppConfig,
    private readonly http: HttpClient,
    private readonly traceService: TraceService
  ) {}

  timeoutMs = 20000;

  retryConfig = {
    retries: 1,
    backoffMs: 1000,
    retryDelayMs: 1000,
    tooBusyRetries: 2,
    checksumRetries: 2,
  };

  private processHeaders(trace?: HTTPTraceHeader): HttpHeaders {
    let headers = new HttpHeaders({
      'ngsw-bypass': 'bypass',
      'Cache-Control': 'no-cache',
    });

    if (trace) {
      headers = new HttpHeaders({
        Traceparent: trace?.traceparent ?? '',
        Tracestate: trace?.tracestate ?? '',
        'ngsw-bypass': 'bypass',
        'Cache-Control': 'no-cache',
        'Access-Control-Expose-Headers':
          'Traceparent,TraceState,Orig-Tracestate,Orig-Traceparent,X-Tp', // expose the trace headers in response
      });
    }
    return headers;
  }

  getHelpBundleUrl(): string {
    const bundleKey = `bundles%2ftenant-help.${this.tenantConfig.env}.bundle.help`;
    switch (this.tenantConfig.env) {
      case 'local':
        return `https://local-assets.sidkik.app/help/${bundleKey}?alt=media`;
      default:
        return `https://assets.sidkik.app/help/${bundleKey}`;
    }
  }

  getBundle(trace?: HTTPTraceHeader): Observable<ArrayBuffer> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminGetHelpBundle);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminGetHelpBundle
      );
      internalTrace = true;
    }
    const url = this.getHelpBundleUrl();
    // 'http://127.0.0.1:9199/v0/b/fir-account-3f2bd.appspot.com/o/help-bundles%2ftenant-help-bundle?alt=media';
    // http://127.0.0.1:9199/v0/b/fir-account-3f2bd.appspot.com/o/fir-account-3f2bd%2Fassets%2Fsidkik.com%20-%20features%20images.gif?alt=media&token=6f43db18-7fbf-473f-9b39-d999762b268d
    // const url =
    //   'http://127.0.0.1:9199/v0/b/fir-account-3f2bd.appspot.com/o/fir-account-3f2bd%2Fassets%2Fsidkik.com%20-%20features%20images.gif?alt=media&token=6f43db18-7fbf-473f-9b39-d999762b268d';

    logger.trace('sidkik-api:help.service:getBundle', 'bundle location', url);
    // get the resp body which is a string
    return this.http.get(this.getHelpBundleUrl(), {
      headers: this.processHeaders(trace),
      responseType: 'arraybuffer' as const,
    });
  }

  generateBundle(trace?: HTTPTraceHeader): Observable<void> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminGenerateHelpBundle);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminStartCampaignRun
      );
      internalTrace = true;
    }
    return this.http
      .post<void>(
        `${this.tenantConfig.api.endpoint}/admin/help/bundle`,
        {},
        {
          headers: this.processHeaders(trace),
        }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminGenerateHelpBundle, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminGenerateHelpBundle);
        })
      );
  }
}
