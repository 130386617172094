import {
  ChangeDetectorRef,
  Component,
  Inject,
  InjectionToken,
  OnInit,
  HostBinding,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AutoDestroy } from '@sidkik/shared';
import { animate, style, transition, trigger } from '@angular/animations';

export type TooltipData = { tip: string; delay?: number };
export const TOOLTIP_DATA = new InjectionToken<TooltipData>(
  'Data to display in tooltip'
);

@Component({
  selector: 'sidkik-tooltip-container',
  templateUrl: './tooltip-container.component.html',
  animations: [
    trigger('tooltipAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0.95)' }),
        animate('150ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        animate(
          '100ms ease-in',
          style({ opacity: 0, transform: 'scale(0.95)' })
        ),
      ]),
    ]),
  ],
})
export class TooltipContainerComponent implements OnInit {
  @AutoDestroy()
  hideMe$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  @AutoDestroy()
  tip$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  @HostBinding('class') get classes() {
    return 'tooltip-container';
  }

  constructor(
    @Inject(TOOLTIP_DATA) public tooltipData: TooltipData,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.tip$.next(this.tooltipData.tip);
    setTimeout(() => {
      this.hideMe$.next(false);
      this.changeDetectorRef.markForCheck();
    }, this.tooltipData.delay ?? 0);
  }
}
