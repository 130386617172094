import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BreadcrumbsService } from './services/breadcrumbs.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromGlobal from './+state/global.reducer';
import { GlobalEffects } from './+state/global.effects';
import { GlobalFacade } from './+state/global.facade';
import { AssetService } from './services/asset.service';
import { EventsService } from './services/events.service';
import { TraceService } from './services/trace.service';
import { FaviconService } from './services/favicon.service';
import { NavigationService } from './services/navigation.service';
import { ActionService } from './services/action.service';
import { ClientCryptoService } from './services/client-crypto.service';
import { GroupCryptoService } from './services/group-crypto.service';
import { HelpContextualTooltipService } from './services/help-contextual-tooltip.service';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    StoreModule.forFeature(
      fromGlobal.GLOBAL_FEATURE_KEY,
      fromGlobal.globalReducer
    ),
    EffectsModule.forFeature([GlobalEffects]),
  ],
  providers: [GlobalFacade, AssetService],
})
export class GlobalModule {
  // inject into constructor to get the service started
  constructor(
    private breadcrumbsService: BreadcrumbsService,
    private eventsService: EventsService,
    private traceService: TraceService,
    private faviconService: FaviconService,
    private navigationService: NavigationService,
    private actionService: ActionService,
    private clientCryptoService: ClientCryptoService,
    private groupCryptoService: GroupCryptoService,
    private helpContextualTooltipService: HelpContextualTooltipService
  ) {}
}
