import { ComboBoxOption } from '../../models';
import { addProductType } from './catalog';
import {
  addAutomationType,
  addChallengeType,
  addCourseType,
  addEmailType,
  addFlowType,
  addFormType,
  addPageType,
  addPostType,
} from './library';
import { addCampaignType, addCustomerTagType, addListType } from './people';
import { addCouponType } from './shop';

// action button available actions used in the help context
export const actionButtonLookups: ComboBoxOption[] = [
  {
    label: 'Add Flow',
    value: addFlowType,
  },
  {
    label: 'Add Email',
    value: addEmailType,
  },
  {
    label: 'Add Page',
    value: addPageType,
  },
  {
    label: 'Add Form',
    value: addFormType,
  },
  {
    label: 'Add Course',
    value: addCourseType,
  },
  {
    label: 'Add Challenge',
    value: addChallengeType,
  },
  {
    label: 'Add Post',
    value: addPostType,
  },
  {
    label: 'Add Product',
    value: addProductType,
  },
  {
    label: 'Add Coupon',
    value: addCouponType,
  },
  {
    label: 'Add Automation',
    value: addAutomationType,
  },
  {
    label: 'Add Customer List',
    value: addListType,
  },
  {
    label: 'Add Customer Tag',
    value: addCustomerTagType,
  },
  {
    label: 'Add Campaign',
    value: addCampaignType,
  },
];

export const actionButtonActionText: Record<
  string,
  { title: string; message: string }
> = {
  [addFlowType]: { title: 'Add Flow', message: 'Your flow has been added.' },
  [addEmailType]: { title: 'Add Email', message: 'Your email has been added.' },
  [addPageType]: { title: 'Add Page', message: 'Your page has been added.' },
  [addFormType]: { title: 'Add Form', message: 'Your form has been added.' },
  [addCourseType]: {
    title: 'Add Course',
    message: 'Your course has been added.',
  },
  [addChallengeType]: {
    title: 'Add Challenge',
    message: 'Your challenge has been added.',
  },
  [addPostType]: { title: 'Add Post', message: 'Your post has been added.' },
  [addProductType]: {
    title: 'Add Product',
    message: 'Your product has been added.',
  },
  [addCouponType]: {
    title: 'Add Coupon',
    message: 'Your coupon has been added.',
  },
  [addAutomationType]: {
    title: 'Add Automation',
    message: 'Your automation has been added.',
  },
  [addListType]: {
    title: 'Add Customer List',
    message: 'Your customer list has been added.',
  },
  [addCustomerTagType]: {
    title: 'Add Customer Tag',
    message: 'Your customer tag has been added.',
  },
  [addCampaignType]: {
    title: 'Add Campaign',
    message: 'Your campaign has been added.',
  },
};
