import { createAction, props } from '@ngrx/store';
import {
  addBreadcrumbType,
  setFooterNavigationType,
  setHeaderNavigationType,
  setPasswordType,
} from '../constants/actions/global';
import { TrailItem } from '../services/breadcrumbs.service';
import { NavigationType, setPPKType } from '../constants';

export const addBreadcrumb = createAction(
  addBreadcrumbType,
  props<{ items: TrailItem[] }>()
);

export const setHeaderNavigation = createAction(
  setHeaderNavigationType,
  props<{ navigationType: NavigationType }>()
);

export const setFooterNavigation = createAction(
  setFooterNavigationType,
  props<{ navigationType: NavigationType }>()
);

export const setPassword = createAction(
  setPasswordType,
  props<{ password: string }>()
);

export const setPPK = createAction(setPPKType, props<{ ppk: any }>());
