import { Injectable } from '@angular/core';
import { AllHelpTooltipMaps, HelpToolTipMap } from '../constants/help/tooltips';

@Injectable({
  providedIn: 'root',
})
export class HelpContextualTooltipService {
  tooltipMap = HelpToolTipMap;

  getTooltip(key: AllHelpTooltipMaps) {
    return this.tooltipMap[key];
  }
}
