import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AuthzRoutes,
  canActivateAuthenticated,
  canActivateMe,
  canMatchAuthenticated,
  canMatchMe,
} from '@sidkik/authz';
import { routes as ShopRoutes } from '@sidkik/shop';
import { ContainerComponent } from '@sidkik/client-layout';
import { canActivateDBReady, canMatchDBReady } from '@sidkik/db';
import { FlushComponent } from './flush.component';

const routes: Routes = [
  {
    path: 'debug-flush',
    component: FlushComponent,
  },
  {
    path: 'auth',
    children: AuthzRoutes,
    // loadChildren: () => import('@sidkik/authz').then((m) => m.AuthzModule),
  },
  {
    path: 'affiliate',
    loadChildren: () =>
      import('@sidkik/affiliate-portal').then((m) => m.AffiliateModule),
  },
  {
    canMatch: [canMatchAuthenticated, canMatchDBReady, canMatchMe],
    canActivate: [canActivateAuthenticated, canActivateDBReady, canActivateMe],
    path: 'my-activities/viewer',
    loadChildren: () =>
      import('@sidkik/my-activities').then((m) => m.MyActivitiesModule),
  },
  {
    path: '',
    component: ContainerComponent,
    children: [
      {
        canActivate: [
          canActivateAuthenticated,
          canActivateDBReady,
          canActivateDBReady,
        ],
        canMatch: [canMatchAuthenticated, canMatchDBReady, canMatchMe],
        path: 'my-activities',
        loadChildren: () =>
          import('@sidkik/my-activities').then((m) => m.MyActivitiesModule),
      },
      {
        canActivate: [
          canActivateAuthenticated,
          canActivateDBReady,
          canActivateDBReady,
        ],
        canMatch: [canMatchAuthenticated, canMatchDBReady, canMatchMe],
        path: 'my-account',
        loadChildren: () =>
          import('@sidkik/my-account').then((m) => m.MyAccountModule),
      },
      {
        canMatch: [canMatchDBReady],
        canActivate: [canActivateDBReady],
        path: 'shop',
        children: ShopRoutes,
        // loadChildren: () => import('@sidkik/shop').then((m) => m.ShopModule),
      },
      {
        canMatch: [canMatchDBReady],
        canActivate: [canActivateDBReady],
        path: 'posts',
        loadChildren: () => import('@sidkik/posts').then((m) => m.PostsModule),
      },
      {
        canMatch: [canMatchDBReady],
        canActivate: [canActivateDBReady],
        path: 'learn',
        loadChildren: () => import('@sidkik/help').then((m) => m.HelpModule),
      },
      {
        canMatch: [canMatchDBReady],
        canActivate: [canActivateDBReady],
        path: 'community',
        loadChildren: () =>
          import('@sidkik/my-community').then((m) => m.MyCommunityModule),
      },
      {
        canMatch: [canMatchDBReady],
        canActivate: [canActivateDBReady],
        path: ':pageSlug',
        loadChildren: () => import('@sidkik/pages').then((m) => m.PagesModule),
      },
      {
        canMatch: [canMatchDBReady],
        canActivate: [canActivateDBReady],
        path: '',
        loadChildren: () => import('@sidkik/pages').then((m) => m.PagesModule),
      },
    ],
  },
  { path: '**', component: ContainerComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledNonBlocking',
      // Restore the last scroll position
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 0],
      // Enable scrolling to anchors
      anchorScrolling: 'enabled',
      // enableTracing: true,
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class RoutingModule {}
