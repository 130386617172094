<div [class.tw-flex-col]="!vertical" [class.tw-flex-row]="vertical" class="tw-flex tw-h-full">
  <div class="tabs">
    <div class="tw-block">
      <div class="tw-border-b tw-border-gray-200">
        @if (dndEnabled) {
        <nav [class.tw-flex-col]="vertical" cdkDropList [cdkDropListOrientation]="vertical ? 'vertical' : 'horizontal'"
          (cdkDropListDropped)="onTabDrop($event)" [class.tw-space-x-8]="!vertical"
          class="-tw-mb-px tw-flex tw-justify-center xs:tw-justify-start tw-overflow-x-auto" aria-label="Tabs">
          @for (tab of tabs; track tab) {
          @if (tab.draggable) {
          <a [class.tw-border-transparent]="!tab.active" [class.tw-border-primary-500]="tab.active"
            [class.tw-text-primary-600]="tab.active" (click)="selectTab(tab)" cdkDrag [cdkDragData]="tab"
            cdkDragPreviewClass="tw-opacity-90" [cdkDragDisabled]="!dndEnabled" class="tw-cursor-pointer tw-text-gray-500 hover:tw-text-primary-700 hover:tw-border-primary-300
             tw-whitespace-nowrap tw-py-4 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm">
            <div class="tw-flex tw-items-center">
              <div cdkDragHandle class="tw-mr-3 tw-cursor-move tw-touch-none" [class.tw-cursor-default]="!dndEnabled">
                <svg class="tw-h-5 tw-w-5 tw-text-gray-400" viewBox="0 0 32 32">
                  <path stroke="currentColor" fill="currentColor"
                    d="M9.125 27.438h4.563v4.563H9.125zm9.188 0h4.563v4.563h-4.563zm-9.188-9.125h4.563v4.563H9.125zm9.188 0h4.563v4.563h-4.563zM9.125 9.125h4.563v4.563H9.125zm9.188 0h4.563v4.563h-4.563zM9.125 0h4.563v4.563H9.125zm9.188 0h4.563v4.563h-4.563z">
                  </path>
                </svg>
              </div>
              @if (tab.showHelpIcon && tab.tooltipKey) {
              {{tab.title}} <sidkik-help-context-tooltip [tooltipKey]="tab.tooltipKey"></sidkik-help-context-tooltip>
              } @else if (tab.tooltipKey ) {
              <span [sidkikTooltip]="getHelpContextualTooltip(tab.tooltipKey)"
                [showDelay]="tab.showDelay">{{tab.title}}</span>
              } @else {
              {{tab.title}}
              }
            </div>

            <span *ngIf="tab.infoBadgeCount && tab.infoBadgeCount >=0"
              class="tw-bg-primary-100 tw-text-primary-600 tw-ml-3 tw-hidden tw-rounded-full tw-py-0.5 tw-px-2.5 tw-text-xs tw-font-medium md:tw-inline-block">{{tab.infoBadgeCount}}</span>
            <span *ngIf="tab.warnBadgeCount && tab.warnBadgeCount >=0"
              class="tw-bg-warn-100 tw-text-warn-600 tw-ml-3 tw-hidden tw-rounded-full tw-py-0.5 tw-px-2.5 tw-text-xs tw-font-medium md:tw-inline-block">{{tab.warnBadgeCount}}</span>
            <span *ngIf="tab.errorBadgeCount && tab.errorBadgeCount >=0"
              class="tw-bg-error-100 tw-text-error-600 tw-ml-3 tw-hidden tw-rounded-full tw-py-0.5 tw-px-2.5 tw-text-xs tw-font-medium md:tw-inline-block">{{tab.errorBadgeCount}}</span>
          </a>
          } @else {
          <a [class.tw-border-transparent]="!tab.active" [class.tw-border-primary-500]="tab.active"
            [class.tw-text-primary-600]="tab.active" (click)="selectTab(tab)"
            class="tw-cursor-pointer tw-text-gray-500 hover:tw-text-primary-700 hover:tw-border-primary-300 tw-whitespace-nowrap tw-py-4 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm tw-flex tw-items-center">
            @if (tab.showHelpIcon && tab.tooltipKey) {
            {{tab.title}} <sidkik-help-context-tooltip [tooltipKey]="tab.tooltipKey"></sidkik-help-context-tooltip>
            } @else if (tab.tooltipKey ) {
            <span [sidkikTooltip]="getHelpContextualTooltip(tab.tooltipKey)"
              [showDelay]="tab.showDelay">{{tab.title}}</span>
            } @else {
            {{tab.title}}
            }
            <span *ngIf="tab.infoBadgeCount && tab.infoBadgeCount >=0"
              class="tw-bg-primary-100 tw-text-primary-600 tw-ml-3 tw-hidden tw-rounded-full tw-py-0.5 tw-px-2.5 tw-text-xs tw-font-medium md:tw-inline-block">{{tab.infoBadgeCount}}</span>
            <span *ngIf="tab.warnBadgeCount && tab.warnBadgeCount >=0"
              class="tw-bg-warn-100 tw-text-warn-600 tw-ml-3 tw-hidden tw-rounded-full tw-py-0.5 tw-px-2.5 tw-text-xs tw-font-medium md:tw-inline-block">{{tab.warnBadgeCount}}</span>
            <span *ngIf="tab.errorBadgeCount && tab.errorBadgeCount >=0"
              class="tw-bg-error-100 tw-text-error-600 tw-ml-3 tw-hidden tw-rounded-full tw-py-0.5 tw-px-2.5 tw-text-xs tw-font-medium md:tw-inline-block">{{tab.errorBadgeCount}}</span>
          </a>
          }
          }
        </nav>
        } @else {
        <nav [class.tw-flex-col]="vertical" [class.tw-space-x-8]="!vertical"
          class="-tw-mb-px tw-flex tw-justify-center xs:tw-justify-start tw-overflow-x-auto" aria-label="Tabs">
          @for (tab of tabs; track tab) {
          <a [class.tw-border-transparent]="!tab.active" [class.tw-border-primary-500]="tab.active"
            [class.tw-text-primary-600]="tab.active" (click)="selectTab(tab)"
            class="tw-cursor-pointer tw-text-gray-500 hover:tw-text-primary-700 hover:tw-border-primary-300 tw-whitespace-nowrap tw-py-4 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm tw-flex tw-items-center">
            @if (tab.showHelpIcon && tab.tooltipKey) {
            {{tab.title}} <sidkik-help-context-tooltip [tooltipKey]="tab.tooltipKey"></sidkik-help-context-tooltip>
            } @else if (tab.tooltipKey ) {
            <span [sidkikTooltip]="getHelpContextualTooltip(tab.tooltipKey)"
              [showDelay]="tab.showDelay">{{tab.title}}</span>
            } @else {
            {{tab.title}}
            }
            <span *ngIf="tab.infoBadgeCount && tab.infoBadgeCount >=0"
              class="tw-bg-primary-100 tw-text-primary-600 tw-ml-3 tw-hidden tw-rounded-full tw-py-0.5 tw-px-2.5 tw-text-xs tw-font-medium md:tw-inline-block">{{tab.infoBadgeCount}}</span>
            <span *ngIf="tab.warnBadgeCount && tab.warnBadgeCount >=0"
              class="tw-bg-warn-100 tw-text-warn-600 tw-ml-3 tw-hidden tw-rounded-full tw-py-0.5 tw-px-2.5 tw-text-xs tw-font-medium md:tw-inline-block">{{tab.warnBadgeCount}}</span>
            <span *ngIf="tab.errorBadgeCount && tab.errorBadgeCount >=0"
              class="tw-bg-error-100 tw-text-error-600 tw-ml-3 tw-hidden tw-rounded-full tw-py-0.5 tw-px-2.5 tw-text-xs tw-font-medium md:tw-inline-block">{{tab.errorBadgeCount}}</span>
          </a>
          }
        </nav>
        }

      </div>
    </div>
  </div>
  <div class="tab-contents">
    <ng-content></ng-content>
  </div>
</div>