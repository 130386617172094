import { Injectable } from '@angular/core';
import * as shamirSecretSharing from 'shamirs-secret-sharing';
import { Buffer } from 'buffer';

export interface ContentMetadata {
  // Content identification
  contentId: string;
  creatorId: string;
  groupId: string;
  version: number;

  // Timestamps
  createdAt: number;
  modifiedAt: number;

  // Encryption metadata
  algorithm: string;
  keyVersion: number;
  iv: string;
  isRevoked: boolean;
  revocationKeyId?: string;

  // Client-generated hash for integrity
  contentHash: string;
}

@Injectable({
  providedIn: 'root',
})
export class GroupCryptoService {
  private readonly ALGORITHM = 'AES-GCM';
  private readonly KEY_LENGTH = 256;
  private readonly CURRENT_KEY_VERSION = 1;

  // Generate a new MEK for a group
  async generateGroupKey(
    numShares: number,
    threshold: number
  ): Promise<{ mek: Buffer; shares: Buffer[] }> {
    const mek = await window.crypto.subtle.generateKey(
      { name: this.ALGORITHM, length: this.KEY_LENGTH },
      true,
      ['encrypt', 'decrypt']
    );
    const mekBuffer = await window.crypto.subtle.exportKey('raw', mek);
    const shares = shamirSecretSharing.split(Buffer.from(mekBuffer), {
      shares: numShares,
      threshold,
    });
    return { mek: Buffer.from(mekBuffer), shares };
  }

  // Generate a revocation key for a creator
  async generateRevocationKey(): Promise<{ key: CryptoKey; keyId: string }> {
    const key = await window.crypto.subtle.generateKey(
      { name: this.ALGORITHM, length: this.KEY_LENGTH },
      true,
      ['encrypt', 'decrypt']
    );
    // Generate a random ID for this revocation key
    const keyId = Buffer.from(
      window.crypto.getRandomValues(new Uint8Array(16))
    ).toString('hex');
    return { key, keyId };
  }

  // Encrypt content with MEK and protect with creator's revocation key
  async encryptContent(
    content: string,
    mek: CryptoKey,
    revocationKeyId: string,
    options: {
      contentId?: string;
      creatorId: string;
      groupId: string;
    }
  ): Promise<{ encryptedData: string; metadata: ContentMetadata }> {
    const iv = window.crypto.getRandomValues(new Uint8Array(12));
    const encodedContent = new TextEncoder().encode(content);

    // Generate content hash for integrity checking
    const contentHash = await this.generateContentHash(encodedContent);

    const encryptedBuffer = await window.crypto.subtle.encrypt(
      { name: this.ALGORITHM, iv },
      mek,
      encodedContent
    );

    // Generate metadata
    const now = Date.now();
    const metadata: ContentMetadata = {
      contentId: options.contentId || this.generateContentId(),
      creatorId: options.creatorId,
      groupId: options.groupId,
      version: 1,
      createdAt: now,
      modifiedAt: now,
      algorithm: this.ALGORITHM,
      keyVersion: this.CURRENT_KEY_VERSION,
      iv: Buffer.from(iv).toString('base64'),
      isRevoked: false,
      revocationKeyId,
      contentHash,
    };

    return {
      encryptedData: Buffer.from(encryptedBuffer).toString('base64'),
      metadata,
    };
  }

  // Decrypt content using MEK if not revoked
  async decryptContent(
    encryptedData: string,
    metadata: ContentMetadata,
    mek: CryptoKey,
    isContentCreator: boolean
  ): Promise<string | null> {
    try {
      // If content is revoked and user is not the creator, deny access
      if (metadata.isRevoked && !isContentCreator) {
        return null;
      }

      const encryptedBuffer = Buffer.from(encryptedData, 'base64');
      const ivBuffer = Buffer.from(metadata.iv, 'base64');

      const decryptedBuffer = await window.crypto.subtle.decrypt(
        { name: this.ALGORITHM, iv: ivBuffer },
        mek,
        encryptedBuffer
      );

      // Verify content hash
      const decryptedContent = new Uint8Array(decryptedBuffer);
      const verifiedHash = await this.generateContentHash(decryptedContent);
      if (verifiedHash !== metadata.contentHash) {
        console.error('Content integrity check failed');
        return null;
      }

      return new TextDecoder().decode(decryptedBuffer);
    } catch (err) {
      console.error('Decryption failed:', err);
      return null;
    }
  }

  // Reconstruct MEK from shares
  async reconstructMEK(shares: Buffer[]): Promise<CryptoKey> {
    const mekBuffer = shamirSecretSharing.combine(shares);
    return window.crypto.subtle.importKey(
      'raw',
      mekBuffer,
      { name: this.ALGORITHM, length: this.KEY_LENGTH },
      true,
      ['encrypt', 'decrypt']
    );
  }

  // Generate a unique content ID
  private generateContentId(): string {
    const random = window.crypto.getRandomValues(new Uint8Array(16));
    return Buffer.from(random).toString('hex');
  }

  // Generate content hash for integrity checking
  private async generateContentHash(content: Uint8Array): Promise<string> {
    const hashBuffer = await window.crypto.subtle.digest('SHA-256', content);
    return Buffer.from(hashBuffer).toString('hex');
  }
}
