export enum AutomationTargetType {
  // Course related
  CourseSectionCompleted = 'course_section_completed',
  CourseSectionViewed = 'course_section_viewed',
  CourseCompletedPercentage = 'course_completed_percentage',
  CourseViewedPercentage = 'course_viewed_percentage',

  // Challenge related
  ChallengeEventAdded = 'challenge_event_added',
  ChallengeCompletedPercentage = 'challenge_completed_percentage',

  // Email related
  EmailConfirmed = 'email_confirmed',
  EmailMailOpened = 'email_mail_opened',
  EmailMailClicked = 'email_mail_clicked',

  // Campaign related
  CampaignMailOpened = 'campaign_mail_opened',
  CampaignMailClicked = 'campaign_mail_clicked',

  // Customer management
  ProductPurchased = 'purchased_product',
  Tag = 'tag', // for conditional
  TagAdded = 'tag_added', // for trigger
  TagRemoved = 'tag_removed', // for trigger
  List = 'list', // for conditional
  ListAdded = 'list_added', // for trigger
  ListRemoved = 'list_removed', // for trigger
  Unsubscribed = 'unsubscribed',
  SubscriptionRenewal = 'subscription_renewal',
  CourseAccess = 'course_access',
  ChallengeAccess = 'challenge_access',
  MembershipAccess = 'membership_access',
  DoNotContactMarked = 'do_not_contact_marked',
  DoNotContactUnmarked = 'do_not_contact_unmarked',
  IsMember = 'is_member',
  IsSubscriber = 'is_subscriber',

  // Forms
  FormSubmitted = 'form_submitted',
  FormFieldChecked = 'form_field_checked',

  // User types
  SubscriberCreated = 'subscriber_created',
  MemberCreated = 'member_created',
  AffiliateCreated = 'affiliate_created',

  // Platform
  PlatformAssigned = 'platform_assigned',
  IsTenantTrial = 'is_tenant_trial',
  WasCustomerTrial = 'was_customer_trial',

  // Pages
  PageViewed = 'page_viewed',
  PostViewed = 'post_viewed',
}

export interface AutomationBaseDefinition {
  name: string;
  target: AutomationTargetType;
  entity: string;
}
