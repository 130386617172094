export const libraryStateKey = 'library';
export const communityStateKey = 'commmunity';
export const rendererPostsStateKey = 'rendererPosts';
export const rendererHelpStateKey = 'rendererHelp';
export const rendererPagesStateKey = 'rendererPages';
export const pageStateKey = 'pages';
export const emailStateKey = 'emails';
export const workflowStateKey = 'workflows';
export const formStateKey = 'forms';
export const emailTestStateKey = 'emailTests';
export const postStateKey = 'posts';
export const mapStateKey = 'maps';
export const assetStateKey = 'assets';
export const courseStateKey = 'courses';
export const flowStateKey = 'flows';
export const challengeStateKey = 'challenges';
export const templateStateKey = 'templates';
export const contentStateKey = 'content';
export const tagStateKey = 'tags';
export const campaignStateKey = 'campaigns';
export const campaignRunStateKey = 'campaignRuns';
export const automationStateKey = 'automations';
export const automationExecutionStateKey = 'automationExecutions';
export const helpStateKey = 'help';
export const helpTagStateKey = 'helpTags';
