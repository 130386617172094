import { createAction, props } from '@ngrx/store';
import {
  initializeDBFailureType,
  initializeDBSuccessType,
  initializeDBType,
  MonitorDocumentFailure,
  monitorDocumentFailureType,
  MonitorDocumentSuccess,
  monitorDocumentSuccessType,
  setPPKType,
  getPPKType,
  workerLoggedInType,
  storePPKType,
} from '@sidkik/global';
import { PPKProperties } from '../models/ppk';

export const initializeDB = createAction(initializeDBType);

export const initializeDBSuccess = createAction(initializeDBSuccessType);

export const initializeDBFailure = createAction(
  initializeDBFailureType,
  props<{ error: any }>()
);

export const monitorDocumentSuccess = createAction(
  monitorDocumentSuccessType,
  props<MonitorDocumentSuccess>()
);

export const monitorDocumentFailure = createAction(
  monitorDocumentFailureType,
  props<MonitorDocumentFailure>()
);

export const workerLoggedIn = createAction(
  workerLoggedInType,
  props<{ uid: string }>()
);

export const storePPK = createAction(
  storePPKType,
  props<{ ppk: PPKProperties }>()
);

export const getPPK = createAction(getPPKType, props<{ id: string }>());
