import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  CartItemProperties,
  ProductProperties,
  ProductSubType,
  PurchasableProperties,
  SKUProperties,
} from '@sidkik/db';
import {
  AppConfig,
  APP_CONFIG,
  EventsService,
  ScreenType,
} from '@sidkik/global';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  filter,
  lastValueFrom,
  take,
  takeUntil,
} from 'rxjs';
import { ShopFacade } from '../../../+state/shop.facade';
import { CartFacade } from '../../../+state/cart/cart.facade';
import { DOCUMENT } from '@angular/common';
import { SchedulingService } from '@sidkik/sidkik-api';
import { ProductFacade } from '../../../+state/product/product.facade';
import { EventInput } from '@fullcalendar/core';
import { DateTime } from 'luxon';
import { NotificationService } from '@sidkik/ui';
import { BaseDestroyComponent } from '@sidkik/shared';
import { AutoDestroy } from '@sidkik/shared';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'sidkik-product-detail',
  templateUrl: './product-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductDetailComponent
  extends BaseDestroyComponent
  implements OnInit
{
  selectedPurchasable$!: Observable<Partial<PurchasableProperties> | undefined>;
  myLink = '';
  products: ProductProperties[] = [];
  productSubscription!: Subscription;
  @AutoDestroy()
  events$: BehaviorSubject<EventInput[] | undefined> = new BehaviorSubject<
    EventInput[] | undefined
  >(undefined);
  eventGuid = 0;
  @AutoDestroy()
  selectedEvent$: BehaviorSubject<EventInput | undefined> = new BehaviorSubject<
    EventInput | undefined
  >(undefined);
  @AutoDestroy()
  buyEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  productSubType = ProductSubType;

  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    @Inject(DOCUMENT) private document: Document,
    private shopFacade: ShopFacade,
    private cartFacade: CartFacade,
    private eventsService: EventsService,
    private schedulingService: SchedulingService,
    private productFacade: ProductFacade,
    private notificationService: NotificationService,
    private titleService: Title,
    private metaService: Meta
  ) {
    super();
  }

  ngOnInit(): void {
    this.productFacade.allProducts$
      .pipe(takeUntil(this.destroy$))
      .subscribe((products) => {
        this.products = products;
      });
    this.myLink = this.document.location.href;
    this.selectedPurchasable$ = this.shopFacade.selectedPurchasable$;
    this.selectedPurchasable$
      .pipe(
        filter((ci) => ci != undefined),
        take(1)
      )
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(async (ci) => {
        if (!ci?.data) return;
        const pType = ci.data.product?.data?.subtype;
        const quantity = ci.data.product?.data?.quantity;

        this.titleService.setTitle(ci.data.product?.data?.name);
        this.metaService.updateTag({
          name: 'description',
          content: ci.data.product?.data?.description,
        });
        this.eventsService.trackScreenView(
          ci.data.product?.data?.name,
          `shop/products/${ci?.data?.product?.id}`,
          {
            rootType: ScreenType.Product,
            rootId: ci?.data?.product?.id,
          }
        );
        this.eventsService.viewItem(ci as PurchasableProperties);
        if (pType && pType === ProductSubType.Session && quantity === 1) {
          try {
            const evts = await this.getEvents(ci.data.product.id);
            if (evts.length > 0) {
              this.buyEnabled$.next(true);
            } else {
              this.buyEnabled$.next(false);
            }
            this.events$.next(evts);
            return;
          } catch (e) {
            logger.error('shop:product-detail', 'Error loading schedule', e);
            setTimeout(() => {
              this.notificationService.showError(
                'Error Loading Schedule',
                'There was an error loading the schedule. Please try again later.'
              );
            });
            this.buyEnabled$.next(false);
          }
        }
        this.events$.next(undefined);
      });
  }

  clearSelectedEvent() {
    this.selectedEvent$.next(undefined);
  }

  selectEvent(event: EventInput) {
    this.selectedEvent$.next(event);
  }

  showAvailability(purchasable: Partial<PurchasableProperties>) {
    if (!purchasable || !purchasable.data) {
      return false;
    }
    return (
      purchasable.data.product?.data?.subtype === ProductSubType.Session &&
      purchasable.data.product?.data?.quantity === 1
    );
  }

  async getEvents(productId: string): Promise<EventInput[]> {
    const evts: EventInput[] = [];
    const resp = await lastValueFrom(
      this.schedulingService.getAvailabilityByProduct(
        productId,
        DateTime.local().zoneName.replace('/', '__')
      )
    );
    if (!resp.availabilities || resp.availabilities?.length === 0) {
      return evts;
    }
    // filter out availabilities from now utc to minHoursBeforeBooking to enforce min booking time
    const now = DateTime.utc();
    const minTime = now.plus({ hours: resp.minHoursBeforeBooking });
    resp.availabilities = resp.availabilities.filter((a) => {
      const startUnixMS = a.startTimeUnix * 1000;
      const start = DateTime.fromMillis(startUnixMS);
      return start >= minTime;
    });
    // convert to EventInput
    resp.availabilities?.forEach((a) => {
      const startUnixMS = a.startTimeUnix * 1000;
      const durationMS = a.duration * 60 * 1000;
      const start = new Date(startUnixMS);
      const end = new Date(startUnixMS + durationMS);
      const productName = this.products.find((p) => p.id === a.productId)?.data
        .name;
      evts.push({
        id: String(this.eventGuid++),
        title: productName,
        start: start.toISOString(),
        end: end.toISOString(),
        extendedProps: {
          productId: a.productId,
          userId: a.userId,
          availability: a,
          userName: a.userName,
          userAvatar: a.userAvatar,
        },
      });
    });

    return evts;
  }

  addItemToCart(item: Partial<PurchasableProperties>, sku: SKUProperties) {
    if (!item.data) return;
    const selectedEvent = this.selectedEvent$.value;
    // check if product is a session and quantity is 1 and has an appointment request
    if (
      item.data.product?.data?.subtype === ProductSubType.Session &&
      item.data.product?.data?.quantity === 1 &&
      !selectedEvent
    ) {
      setTimeout(() => {
        this.notificationService.showError(
          'Select Appointment Time',
          'Please select an appointment time from the availability calendar'
        );
      });
      return;
    }
    const cartItem: Partial<CartItemProperties> = {
      data: {
        product: item.data.product,
        sku: sku,
      },
    };
    if (this.selectedEvent$.value) {
      (cartItem as CartItemProperties).data.appointmentRequest = {
        userId: selectedEvent?.extendedProps?.['availability']?.['userId'],
        startTime:
          selectedEvent?.extendedProps?.['availability']?.['startTimeUnix'],
        duration: selectedEvent?.extendedProps?.['availability']?.['duration'],
        userTimeZone: DateTime.local().zoneName,
      };
    }
    // add the timezone if it is a session - allows admin to schedule in user's timezone
    if (
      item.data.product?.data?.subtype === ProductSubType.Session &&
      !selectedEvent
    ) {
      (cartItem as CartItemProperties).data.appointmentRequest = {
        userTimeZone: DateTime.local().zoneName,
      };
    }
    this.cartFacade.addCartItem(cartItem);
  }
}
