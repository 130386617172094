import { Injectable } from '@angular/core';
import { LocalStorageService } from '../services/local-storage.service';
import * as DbActions from './db.actions';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { StorageFactory } from '../models';
import { PPK } from '../models/ppk';

@Injectable()
export class DbEffects {
  storePPK$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DbActions.storePPK),
        tap(async (a: any) => {
          logger.trace('db:db.effects.ts:storePPK', 'storePPK', a);
          // create the factory ppk object
          const ppk = StorageFactory.getFactory(PPK, a.ppk).toStorage();
          await this.lsService.upsertPPK(ppk as PPK);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly lsService: LocalStorageService,
    private readonly actions$: Actions
  ) {}
}
