import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  addFlowType,
  addEmailType,
  addPageType,
  addFormType,
  addCourseType,
  addChallengeType,
  addPostType,
  addProductType,
  addCouponType,
  addAutomationType,
  addListType,
  addCustomerTagType,
  addCampaignType,
} from '../constants';

@Injectable({
  providedIn: 'root',
})
export class ActionService {
  constructor(private readonly store: Store) {}

  launchAction(actionId: string) {
    switch (actionId) {
      case addFlowType:
        this.store.dispatch({ type: actionId, flow: undefined });
        break;
      case addEmailType:
        this.store.dispatch({ type: actionId, email: undefined });
        break;
      case addPageType:
        this.store.dispatch({ type: actionId, page: undefined });
        break;
      case addFormType:
        this.store.dispatch({ type: actionId, form: undefined });
        break;
      case addCourseType:
        this.store.dispatch({ type: actionId, course: undefined });
        break;
      case addChallengeType:
        this.store.dispatch({ type: actionId, challenge: undefined });
        break;
      case addPostType:
        this.store.dispatch({ type: actionId, post: undefined });
        break;
      case addProductType:
        this.store.dispatch({ type: actionId, product: undefined });
        break;
      case addCouponType:
        this.store.dispatch({ type: actionId, coupon: undefined });
        break;
      case addAutomationType:
        this.store.dispatch({ type: actionId, automation: undefined });
        break;
      case addListType:
        this.store.dispatch({ type: actionId, list: undefined });
        break;
      case addCustomerTagType:
        this.store.dispatch({ type: actionId, customerTag: undefined });
        break;
      case addCampaignType:
        this.store.dispatch({ type: actionId, campaign: undefined });
        break;
      default:
        console.error(`Action not found: ${actionId}`);
        break;
    }
  }
}
