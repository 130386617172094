import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { APP_CONFIG, AppConfig, FaviconService } from '@sidkik/global';
import { AppUpdateService } from './services/app-update.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AuthzFacade } from '@sidkik/authz';
import { delay, filter, take, timer } from 'rxjs';

@Component({
  selector: 'sidkik-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'client';
  linkedInInsightsId;
  fbPixelId;
  activeCampaignId;
  clarityId;
  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    @Inject('APP_VERSION') public version: string,
    @Inject(DOCUMENT) private doc: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private appUpdateService: AppUpdateService,
    private faviconService: FaviconService,
    private authzFacade: AuthzFacade
  ) {
    this.linkedInInsightsId = this.appConfig?.addon?.linkedInInsightsId;
    this.activeCampaignId = this.appConfig?.addon?.activeCampaignId;
    this.fbPixelId = this.appConfig?.addon?.fbPixelId;
    this.clarityId = this.appConfig?.addon?.clarityId;
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        if ((window as any)['clarity']) {
          logger.info('app:clarity', 'setting version', version);
          (window as any)['clarity']('set', 'version', version);
        }
      }, 30000);
    }
  }

  async ngOnInit() {
    this.appUpdateService.init(this.appConfig);
    this.faviconService.setFaviconByUrl(
      this.appConfig.branding?.favicon || '/assets/icons/icon-72x72.v1.png'
    );
    if (isPlatformBrowser(this.platformId)) this.addTawkTo();
  }

  /**
   * add tawk.to integration if enabled in config
   */
  addTawkTo() {
    if (this.appConfig?.addon?.tawkTo?.chatLink) {
      const s = this.doc.createElement('script');
      s.type = 'text/javascript';
      s.innerHTML = `var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    (function(){
    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    s1.async=true;
    s1.src='${this.appConfig.addon.tawkTo.chatLink}';
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    s0.parentNode.insertBefore(s1,s0);
    })();`;
      const head = this.doc.getElementsByTagName('head')[0];
      head.appendChild(s);
    }

    this.authzFacade.me$
      .pipe(
        filter((me) => me !== null && me !== undefined),
        take(1)
      )
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(async (me) => {
        if ((window as any)['clarity']) {
          logger.info('app:clarity', 'setting customer', me?.id);
          (window as any)['clarity']('set', 'customer', me?.id);
          (window as any)['clarity']('identify', me?.id);
          (window as any)['clarity'](
            'set',
            'tenant',
            this.appConfig.firebase.tenantId
          );
        }
      });

    this.authzFacade.user$
      .pipe(
        filter((user) => user !== null && user !== undefined),
        take(1)
      )
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(async (user) => {
        if (this.appConfig?.addon?.tawkTo?.chatLink) {
          const win = this.doc.defaultView;

          if (win && (win as any)['Tawk_API']) {
            this.authzFacade.userId$
              .pipe(take(1), delay(10000))
              // eslint-disable-next-line rxjs-angular/prefer-takeuntil
              .subscribe((userId) => {
                const tawkTo = (win as any)['Tawk_API'];
                const attrs = {
                  name: user?.email,
                  email: user?.email,
                };
                tawkTo.setAttributes(attrs, (error: any) => {
                  if (error) {
                    logger.error(
                      'app:tawkto',
                      'unable to set user info for tawk to',
                      error
                    );
                    return;
                  }
                  logger.info('app:tawkto', 'set attributes for tawkTo', attrs);
                });
              });
          }
        }
      });
  }
}
