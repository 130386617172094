export enum MessageType {
  start = 'start',
  init = 'init',
  requestSync = 'requestSync',
  getAllEntities = 'getAllEntities',
  getAllSubEntities = 'getAllSubEntities',
  getEntity = 'getEntity',
  getSubEntity = 'getSubEntity',
  monitorRemoteDocument = 'monitorRemoteDocument',
  monitorRemoteDocumentPerpetually = 'monitorRemoteDocumentPerpetually',
  monitorRemoteCollection = 'monitorRemoteCollection',
  closeMonitorRemoteCollection = 'closeMonitorRemoteCollection',
  closeMonitorRemoteDocumentPerpetually = 'closeMonitorRemoteDocumentPerpetually',
  batchCommitted = 'batch:committed',
  collectionChanged = 'collection:changed',
  documentChanged = 'document:changed',
  setUser = 'setUser',
  workerAuthChanged = 'workerAuthChanged',
  workerAppCheckIssue = 'workerAppCheckIssue',
  updateAppCheckToken = 'updateAppCheckToken',
  refreshAppCheckToken = 'refreshAppCheckToken',
  loadBundle = 'loadBundle',
}
