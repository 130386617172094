import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AutoDestroy } from '@sidkik/shared';
import { HelpTenantAppMap } from '../constants/help/help';
@Injectable({
  providedIn: 'root',
})
export class HelpPanelService {
  @AutoDestroy() private isOpenSubject = new BehaviorSubject<boolean>(false);
  @AutoDestroy() private currentHelpMapSubject =
    new BehaviorSubject<HelpTenantAppMap | null>(null);

  isOpen$ = this.isOpenSubject.asObservable();
  currentHelpMap$ = this.currentHelpMapSubject.asObservable();

  open(helpMap?: HelpTenantAppMap) {
    if (helpMap) {
      this.currentHelpMapSubject.next(helpMap);
    }
    this.isOpenSubject.next(true);
  }

  close() {
    this.isOpenSubject.next(false);
    this.currentHelpMapSubject.next(null);
  }
}
