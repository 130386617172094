/** User Request */

export const loadUserRequestsType = '[people/user-request] load user-requests';
export const loadUserRequestsSuccessType =
  '[people/user-request] load user-requests success';
export const loadUserRequestsFailureType =
  '[people/user-request] load user-requests failure';

export const updateUserRequestType =
  '[people/user-request] update user-request';

export const updateUserRequestSuccessType =
  '[people/user-request] update user-request success';
export const updateUserRequestFailureType =
  '[people/user-request] update user-request failure';

export const selectUserRequestType =
  '[people/user-request] select user-request';

/** User */

export const loadUsersType = '[people/user] load users';
export const loadUsersSuccessType = '[people/user] load users success';
export const loadUsersFailureType = '[people/user] load users failure';

export const updateUserType = '[people/user] update user';

export const updateUserSuccessType = '[people/user] update user success';
export const updateUserFailureType = '[people/user] update user failure';

export const deleteUserType = '[people/user] delete user';
export const deleteUserSuccessType = '[people/user] delete user success';
export const deleteUserFailureType = '[people/user] delete user failure';

export const loadUsersAndSelectUserType =
  '[people/user] load users and select user';
export const loadUsersAndSelectUserSuccessType =
  '[people/user] load users and select user success';
export const loadUsersAndSelectUserFailureType =
  '[people/user] load users and select user failure';

export const selectUserType = '[people/user] select user';

/** Customer */

export const loadCustomersType = '[people/customer] load customers';
export const loadCustomersSuccessType =
  '[people/customer] load customers success';
export const loadCustomersFailureType =
  '[people/customer] load customers failure';

export const loadCustomersFilterType =
  '[people/customer] load customers filters';
export const loadCustomersFilterSuccessType =
  '[people/customer] load customers filters success';
export const loadCustomersFilterFailureType =
  '[people/customer] load customers filters failure';

export const updateCustomerType = '[people/customer] update customer';
export const updateCustomerProgressType =
  '[people/customer] update customer progress';
export const updateCustomerSuccessType =
  '[people/customer] update customer success';
export const updateCustomerFailureType =
  '[people/customer] update customer failure';

export const deleteCustomerType = '[people/customer] delete customer';
export const deleteCustomerSuccessType =
  '[people/customer] delete customer success';
export const deleteCustomerFailureType =
  '[people/customer] delete customer failure';

export const loadCustomersAndSelectCustomerType =
  '[people/customer] load customers and select customer';
export const loadCustomersAndSelectCustomerSuccessType =
  '[people/customer] load customers and select customer success';
export const loadCustomersAndSelectCustomerFailureType =
  '[people/customer] load customers and select customer failure';

export const selectCustomerType = '[people/customer] select customer';
export const updateCustomerFilterType =
  '[people/customer] update customer filter';
export const clearCustomerFilterType =
  '[people/customer] clear customer filter';

export const updateCustomerIntegrationsType =
  '[people/customer] update customer integrations';
export const updateCustomerIntegrationsSuccessType =
  '[people/customer] update customer integrations success';
export const updateCustomerIntegrationsFailureType =
  '[people/customer] update customer integrations failure';

/** Integrations */

export const loadActiveCampaignConfigurationType =
  '[people/integrations] load active campaign configuration';
export const loadActiveCampaignConfigurationSuccessType =
  '[people/integrations] load active campaign configuration success';
export const loadActiveCampaignConfigurationFailureType =
  '[people/integrations] load active campaign configuration failure';

export const loadQuickbooksConfigurationType =
  '[people/integrations] load quickbooks configuration';
export const loadQuickbooksConfigurationSuccessType =
  '[people/integrations] load quickbooks configuration success';
export const loadQuickbooksConfigurationFailureType =
  '[people/integrations] load quickbooks configuration failure';

export const loadStripeConfigurationType =
  '[people/integrations] load stripe configuration';
export const loadStripeConfigurationSuccessType =
  '[people/integrations] load stripe configuration success';
export const loadStripeConfigurationFailureType =
  '[people/integrations] load stripe configuration failure';

/** Orders */

export const loadAllOrdersType = '[accounting/order] load orders';
export const loadAllOrdersSuccessType =
  '[accounting/order] load orders success';
export const loadAllOrdersFailureType =
  '[accounting/order] load orders failure';
export const selectAccountingOrderType = '[accounting/order] select order';

export const updateOrderIntegrationsType =
  '[people/order] update order integrations';
export const updateOrderIntegrationsSuccessType =
  '[people/order] update order integrations success';
export const updateOrderIntegrationsFailureType =
  '[people/order] update order integrations failure';

/** AutomationExecution */
export const loadAutomationExecutionsType =
  '[people/automation-execution] load automation executions';
export const loadAutomationExecutionsSuccessType =
  '[people/automation-execution] load automation executions success';
export const loadAutomationExecutionsFailureType =
  '[people/automation-execution] load automation executions failure';
export const selectAutomationExecutionType =
  '[people/automation-execution] select automation execution';

/** CX */

export const loadCXsType = '[people/cx] load cxs';
export const loadCXsSuccessType = '[people/cx] load cxs success';
export const loadCXsFailureType = '[people/cx] load cxs failure';

export const loadCXsAndSelectCXType = '[people/cx] load cxs and select cx';
export const loadCXsAndSelectCXSuccessType =
  '[people/cx] load cxs and select cx success';
export const loadCXsAndSelectCXFailureType =
  '[people/cx] load cxs and select cx failure';

export const selectCXType = '[people/cx] select cx';

/** Affiliate */

export const loadAffiliatesType = '[people/affiliate] load affiliates';
export const loadAffiliatesSuccessType =
  '[people/affiliate] load affiliates success';
export const loadAffiliatesFailureType =
  '[people/affiliate] load affiliates failure';

export const updateAffiliateType = '[people/affiliate] update affiliate';
export const updateAffiliateProgressType =
  '[people/affiliate] update affiliate progress';
export const updateAffiliateSuccessType =
  '[people/affiliate] update affiliate success';
export const updateAffiliateFailureType =
  '[people/affiliate] update affiliate failure';

export const deleteAffiliateType = '[people/affiliate] delete affiliate';
export const deleteAffiliateSuccessType =
  '[people/affiliate] delete affiliate success';
export const deleteAffiliateFailureType =
  '[people/affiliate] delete affiliate failure';

export const loadAffiliatesAndSelectAffiliateType =
  '[people/affiliate] load affiliates and select affiliate';
export const loadAffiliatesAndSelectAffiliateSuccessType =
  '[people/affiliate] load affiliates and select affiliate success';
export const loadAffiliatesAndSelectAffiliateFailureType =
  '[people/affiliate] load affiliates and select affiliate failure';

export const selectAffiliateType = '[people/affiliate] select affiliate';

export const updateAffiliateIntegrationsType =
  '[people/affiliate] update affiliate integrations';
export const updateAffiliateIntegrationsSuccessType =
  '[people/affiliate] update affiliate integrations success';
export const updateAffiliateIntegrationsFailureType =
  '[people/affiliate] update affiliate integrations failure';

/** List */

export const incrListCustomerCountType =
  '[people/list] increment customer count';
export const incrListCustomerCountSuccessType =
  '[people/list] increment customer count success';

export const addListType = '[people/list] add list';
export const addListSuccessType = '[people/list] add list success';
export const addListFailureType = '[people/list] add list failure';

export const loadListsType = '[people/list] load lists';
export const loadListsSuccessType = '[people/list] load lists success';
export const loadListsFailureType = '[people/list] load lists failure';

export const updateListType = '[people/list] update list';
export const updateListProgressType = '[people/list] update list progress';
export const updateListSuccessType = '[people/list] update list success';
export const updateListFailureType = '[people/list] update list failure';

export const deleteListType = '[people/list] delete list';
export const deleteListSuccessType = '[people/list] delete list success';
export const deleteListFailureType = '[people/list] delete list failure';

export const loadListsAndSelectListType =
  '[people/list] load lists and select list';
export const loadListsAndSelectListSuccessType =
  '[people/list] load lists and select list success';
export const loadListsAndSelectListFailureType =
  '[people/list] load lists and select list failure';

export const selectListType = '[people/list] select list';

/** CustomerTag */
export const incrCustomerTagCustomerCountType =
  '[people/tag] increment customer count';
export const incrCustomerTagCustomerCountSuccessType =
  '[people/tag] increment customer count success';
export const addCustomerTagType = '[people/tag] add tag';
export const addCustomerTagSuccessType = '[people/tag] add tag success';
export const addCustomerTagFailureType = '[people/tag] add tag failure';

export const loadCustomerTagsType = '[people/tag] load tags';
export const loadCustomerTagsSuccessType = '[people/tag] load tags success';
export const loadCustomerTagsFailureType = '[people/tag] load tags failure';

export const updateCustomerTagType = '[people/tag] update tag';
export const updateCustomerTagProgressType = '[people/tag] update tag progress';
export const updateCustomerTagSuccessType = '[people/tag] update tag success';
export const updateCustomerTagFailureType = '[people/tag] update tag failure';

export const deleteCustomerTagType = '[people/tag] delete tag';
export const deleteCustomerTagSuccessType = '[people/tag] delete tag success';
export const deleteCustomerTagFailureType = '[people/tag] delete tag failure';

export const loadCustomerTagsAndSelectCustomerTagType =
  '[people/tag] load tags and select tag';
export const loadCustomerTagsAndSelectCustomerTagSuccessType =
  '[people/tag] load tags and select tag success';
export const loadCustomerTagsAndSelectCustomerTagFailureType =
  '[people/tag] load tags and select tag failure';

export const selectCustomerTagType = '[people/tag] select tag';

/** Segment */
export const addSegmentType = '[people/segment] add segment';
export const addSegmentSuccessType = '[people/segment] add segment success';
export const addSegmentFailureType = '[people/segment] add segment failure';

export const loadSegmentsType = '[people/segment] load segments';
export const loadSegmentsSuccessType = '[people/segment] load segments success';
export const loadSegmentsFailureType = '[people/segment] load segments failure';

export const updateSegmentType = '[people/segment] update segment';
export const updateSegmentProgressType =
  '[people/segment] update segment progress';
export const updateSegmentSuccessType =
  '[people/segment] update segment success';
export const updateSegmentFailureType =
  '[people/segment] update segment failure';

export const deleteSegmentType = '[people/segment] delete segment';
export const deleteSegmentSuccessType =
  '[people/segment] delete segment success';
export const deleteSegmentFailureType =
  '[people/segment] delete segment failure';

export const loadSegmentsAndSelectSegmentType =
  '[people/segment] load segments and select segment';
export const loadSegmentsAndSelectSegmentSuccessType =
  '[people/segment] load segments and select segment success';
export const loadSegmentsAndSelectSegmentFailureType =
  '[people/segment] load segments and select segment failure';

export const selectSegmentType = '[people/segment] select segment';

/** Campaign */
export const addCampaignType = '[people/campaign] add campaign';
export const addCampaignSuccessType = '[people/campaign] add campaign success';
export const addCampaignFailureType = '[people/campaign] add campaign failure';

export const loadCampaignsType = '[people/campaign] load campaigns';
export const loadCampaignsSuccessType =
  '[people/campaign] load campaigns success';
export const loadCampaignsFailureType =
  '[people/campaign] load campaigns failure';

export const updateCampaignType = '[people/campaign] update campaign';
export const updateCampaignProgressType =
  '[people/campaign] update campaign progress';
export const updateCampaignSuccessType =
  '[people/campaign] update campaign success';
export const updateCampaignFailureType =
  '[people/campaign] update campaign failure';

export const deleteCampaignType = '[people/campaign] delete campaign';
export const deleteCampaignSuccessType =
  '[people/campaign] delete campaign success';
export const deleteCampaignFailureType =
  '[people/campaign] delete campaign failure';

export const loadCampaignsAndSelectCampaignType =
  '[people/campaign] load campaigns and select campaign';
export const loadCampaignsAndSelectCampaignSuccessType =
  '[people/campaign] load campaigns and select campaign success';
export const loadCampaignsAndSelectCampaignFailureType =
  '[people/campaign] load campaigns and select campaign failure';

export const selectCampaignType = '[people/campaign] select campaign';

/** Campaign Run */
export const addCampaignRunType = '[people/campaign-run] add campaign run';
export const addCampaignRunSuccessType =
  '[people/campaign-run] add campaign run success';
export const addCampaignRunFailureType =
  '[people/campaign-run] add campaign run failure';

export const loadCampaignRunsType = '[people/campaign-run] load campaign runs';
export const loadCampaignRunsSuccessType =
  '[people/campaign-run] load campaign runs success';
export const loadCampaignRunsFailureType =
  '[people/campaign-run] load campaign runs failure';

export const updateCampaignRunType =
  '[people/campaign-run] update campaign run';

export const updateCampaignRunSuccessType =
  '[people/campaign-run] update campaign run success';
export const updateCampaignRunFailureType =
  '[people/campaign-run] update campaign run failure';

export const deleteCampaignRunType =
  '[people/campaign-run] delete campaign run';
export const deleteCampaignRunSuccessType =
  '[people/campaign-run] delete campaign run success';
export const deleteCampaignRunFailureType =
  '[people/campaign-run] delete campaign run failure';

export const loadCampaignRunsAndSelectCampaignRunType =
  '[people/campaign-run] load campaign runs and select campaign run';
export const loadCampaignRunsAndSelectCampaignRunSuccessType =
  '[people/campaign-run] load campaign runs and select campaign run success';
export const loadCampaignRunsAndSelectCampaignRunFailureType =
  '[people/campaign-run] load campaign runs and select campaign run failure';

export const selectCampaignRunType =
  '[people/campaign-run] select campaign run';

/** Customer Order */

export const loadCustomerOrderType = '[people/customer/order] load order';
export const loadCustomerOrderSuccessType =
  '[people/customer/order] load order success';
export const loadCustomerOrderFailureType =
  '[people/customer/order] load order failure';
export const selectCustomerOrderType = '[people/customer/order] select order';

/** Customer Subscription */

export const loadCustomerSubscriptionType =
  '[people/customer/subscription] load subscription';
export const loadCustomerSubscriptionSuccessType =
  '[people/customer/subscription] load subscription success';
export const loadCustomerSubscriptionFailureType =
  '[people/customer/subscription] load subscription failure';
export const selectCustomerSubscriptionType =
  '[people/customer/subscription] select subscription';
