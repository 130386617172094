import { Injectable, OnDestroy } from '@angular/core';
import { select, Store, Action } from '@ngrx/store';

import * as DBActions from './db.actions';
import * as DBSelectors from './db.selectors';
@Injectable()
export class DBFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(select(DBSelectors.getDBInitialized));
  error$ = this.store.pipe(select(DBSelectors.getDBError));
  loggedIn$ = this.store.pipe(select(DBSelectors.getLoggedIn));
  loggedInUser$ = this.store.pipe(select(DBSelectors.getLoggedInUser));
  customerId$ = this.store.pipe(select(DBSelectors.getCustomerId));

  constructor(private readonly store: Store) {}
}
